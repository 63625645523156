import React, { useState, useRef, useContext, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { UserContext } from './Context/User'
import { StoresContext } from './Context/Stores'
import Transition from './Transition'
import SVG from './SVG'
import { getCookie } from '../common'

const Header = ({ history }) => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)

  useEffect(() => {
    if (!user.authenticated && getCookie('canRefresh')) {
      user.login()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.authenticated) {
      storesCtx.getStores()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.authenticated])

  const profileMenuRef = useRef(null)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen)
  }

  const mobileMenuRef = useRef(null)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const stripePortal = async () => {
    setProfileMenuOpen(false)
    const result = await user.stripePortal()
    if (result.redirect) {
      window.location = result.redirect
    }
  }

  return (
    <nav className="bg-indigo-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center flex-shrink-0">
              <SVG name="logo" className="w-[140px] text-indigo-300" />
            </Link>
            <div className="hidden sm:block">
              <div className="ml-10 flex items-baseline">
                <Link
                  to="/"
                  className="ml-4 px-3 py-2 rounded-md text-sm font-semibold text-indigo-200 hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-900"
                >
                  Home
                </Link>
                <Link
                  to="/tools"
                  className="ml-4 px-3 py-2 rounded-md text-sm font-semibold text-indigo-200 hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-900"
                >
                  Tools
                </Link>
                <Link
                  to="/pricing"
                  className="ml-4 px-3 py-2 rounded-md text-sm font-semibold text-indigo-200 hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-900"
                >
                  Pricing
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            <div className="ml-4 flex items-center sm:ml-6">
              {/* Profile dropdown */}
              <div className="ml-3 relative">
                <div>
                  {user.authenticated ? (
                    <button
                      onClick={toggleProfileMenu}
                      className="max-w-xs flex items-center text-sm rounded-full text-indigo-200 focus:text-white focus:outline-none focus:shadow-solid"
                      id="user-menu"
                      aria-label="User menu"
                      aria-haspopup="true"
                    >
                      {user.props.photo ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.props.photo}
                          alt=""
                        />
                      ) : (
                        <>
                          <span className="mr-1">
                            {storesCtx.store.bc_name}
                          </span>
                          <SVG
                            name="user"
                            size={36}
                            className="text-white bg-transparent"
                          />
                        </>
                      )}
                    </button>
                  ) : (
                    <Link
                      to="/login"
                      className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-600 hover:text-white bg-white hover:bg-indigo-700 transition"
                    >
                      Sign in
                    </Link>
                  )}
                </div>

                <Transition
                  nodeRef={profileMenuRef}
                  show={profileMenuOpen}
                  enter="transition ease-out duration-200"
                  enterFrom='opacity-0 translate-y-1"'
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div
                    ref={profileMenuRef}
                    className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                  >
                    <div className="py-1 rounded-md bg-white shadow-xs">
                      {user.authenticated && user.props.isSubscribed && (
                        <button
                          onClick={() => stripePortal()}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Manage subscription
                        </button>
                      )}
                      <Link
                        to="/"
                        onClick={() => {
                          user.logout()
                          storesCtx.reset()
                          setProfileMenuOpen(false)
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex sm:hidden">
            {!user.authenticated && (
              <Link
                to="/login"
                className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-600 hover:text-white bg-white hover:bg-indigo-700 transition"
              >
                Sign in
              </Link>
            )}
            {/* Mobile menu button */}
            <button
              onClick={toggleMobileMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              {/* Menu open: "hidden", Menu closed: "block" */}
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Menu open: "block", Menu closed: "hidden" */}
              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <Transition
        nodeRef={mobileMenuRef}
        show={mobileMenuOpen}
        enter="transition ease-out duration-200"
        enterFrom='opacity-0 translate-y-1"'
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div ref={mobileMenuRef} className="sm:hidden">
          <div className="px-2 pt-2 pb-3 sm:px-3">
            <Link
              to="/"
              onClick={() => setMobileMenuOpen(false)}
              className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              Home
            </Link>
            <Link
              to="/tools"
              onClick={() => setMobileMenuOpen(false)}
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              Tools
            </Link>
            {user.authenticated && (
              <Link
                to="/"
                onClick={() => {
                  setMobileMenuOpen(false)
                  user.logout()
                  storesCtx.reset()
                }}
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
              >
                Sign out
              </Link>
            )}
          </div>

          {user.authenticated && (
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                {user.props.photo && (
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.props.photo}
                      alt=""
                    />
                  </div>
                )}
                <div className="">
                  <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                    {storesCtx.store.bc_name}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Transition>
    </nav>
  )
}

export default withRouter(Header)
