import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import CategoriesDownload from './CategoriesDownload'
import CategoriesImport from './CategoriesImport'

export default function CategoriesManagePage() {
  return (
    <ToolPageLayout2col>
      <CategoriesDownload />
      <CategoriesImport />
    </ToolPageLayout2col>
  )
}
