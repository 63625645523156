import React from 'react'
import SVG from './SVG'

const Loading = () => (
  <div className="mt-24 flex">
    <div className="m-auto">
      <SVG name="spinner" />
    </div>
  </div>
)

export default Loading