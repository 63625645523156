import React from 'react'
import ToolPageLayout from '../ToolPageLayout'
import CouponsDownload from './CouponsDownload'

const CouponsDownloadPage = () => {

  return (
    <ToolPageLayout>
      <CouponsDownload />
    </ToolPageLayout>
  )
}

export default CouponsDownloadPage
