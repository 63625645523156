import React, { useEffect, useState, useContext } from 'react'
import Plausible from 'plausible-tracker'
import { UserContext } from '../Context/User'
import { StoresContext } from '../Context/Stores'
import { API_URL } from '../../config'
import Complete from './Complete'
import ProgressBar from './ProgressBar'
import Results from './Results'
import Error from './Error'

const { trackEvent } = Plausible()

const useFileImport = ({ event, method, file, resultMap, onReset }) => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)

  const url = `${API_URL}/${storesCtx.store.bc_hash}/${method}`

  // const [runId, setRunId] = useState(new Date())
  // const [bytesUploaded, setBytesUploaded] = useState(0)
  const [error, setError] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [result, setResult] = useState({})

  const importComplete = user[method]?.finished
  const resultError = user[method]?.error

  useEffect(() => {
    if (resultError) {
      setError(resultError)
    }
  }, [resultError])

  const reset = () => {
    onReset()
    setUploadProgress(0)
    setResult({})
    setError(null)
    // setBytesUploaded(0)
    // setRunId(new Date())
  }

  // We use xhr instead of newer fetch because
  // fetch does not yet support upload progress
  const upload = () => {
    const xhr = new XMLHttpRequest()
    xhr.upload.addEventListener(
      'progress',
      (evt) => {
        onUploadProgress(evt, xhr)
      },
      false
    )
    xhr.addEventListener(
      'load',
      (evt) => {
        onRequestFinished(evt, xhr)
      },
      false
    )
    xhr.addEventListener(
      'abort',
      (evt) => {
        onUploadAborted(evt, xhr)
      },
      false
    )
    xhr.addEventListener(
      'error',
      (evt) => {
        onUploadFailed(evt, xhr)
      },
      false
    )
    xhr.open('POST', url, true)
    xhr.withCredentials = true
    if (user.csrfToken) {
      xhr.setRequestHeader('csrf-token', user.csrfToken)
    }
    xhr.setRequestHeader('bct-socket', window.BCtools.socket?.id)
    xhr.timeout = 8 * 60 * 60 * 1000 // 8 hours
    xhr.ontimeout = (e) => {
      console.log('xhr timeout', e)
    }
    const formData = new FormData()
    formData.append('file', file)
    xhr.send(formData)
  }

  const onUploadProgress = (evt, xhr) => {
    // console.log('uploadProgress', evt)
    const progress = (evt.loaded * 100) / evt.total
    setUploadProgress(progress)
    // setBytesUploaded(evt.loaded)
  }

  const onRequestFinished = (evt, xhr) => {
    console.log('onRequestFinished', evt, xhr)
    // xhr.status
    setResult(JSON.parse(xhr.response))
    // Not true anymore--API returns immediately after upload
    // We depend on websocket for our import progress
    // setImportComplete(true)
    trackEvent(event || method)
  }

  const onUploadFailed = (evt, xhr) => {
    console.log('uploadFailed', evt)
    setError('Upload failed')
  }

  const onUploadAborted = (evt, xhr) => {
    console.log('uploadAborted', evt)
    setError('Upload canceled')
  }

  useEffect(() => {
    if (file) {
      upload(file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    if (result.error) {
      setError(result.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.error])

  const FileImport = () => (
    <>
      {error && <Error msg={error} />}
      {!error && importComplete && <Complete reset={reset} />}
      {!error && !importComplete && (
        <ProgressBar
          uploadProgress={user[method]?.runId ? 100 : uploadProgress}
          validationProgress={
            resultMap.validated === false
              ? 100
              : (user[method]?.validated / user[method]?.rows_in_file) * 100
          }
          importProgress={
            (user[method]?.processed / user[method]?.rows_in_file) * 100
          }
        />
      )}
      <Results result={user[method]} resultMap={resultMap} />
      {error && (
        <button
          className="flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-gray-700 transition duration-150 ease-in-out"
          onClick={reset}
        >
          Reset
        </button>
      )}
    </>
  )

  return {
    FileImport,
    fileUploadError: error,
    disabled: !user.authenticated || !storesCtx.store.bc_hash,
  }
}

export default useFileImport
