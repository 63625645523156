import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import GiftCertificatesDownload from './GiftCertificatesDownload'
import GiftCertificatesImport from './GiftCertificatesImport'

export default function BrandsManagePage() {
  return (
    <>
      <ToolPageLayout2col>
        <GiftCertificatesDownload />
        <GiftCertificatesImport />
      </ToolPageLayout2col>
    </>
  )
}
