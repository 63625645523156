import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  updated: 'customs info updated',
  deleted: 'customs info deleted',
  created: false,
  entities: false,
}

const ProductCustomsInfoImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Customs info import',
      method: 'products/customs-info/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="Have a backup?">
              Make sure you have a{' '}
              <span className="font-bold">recent, unmodified CSV file</span> in
              case you need to restore your data.{' '}
            </InfoNote>
            <InfoNote title="Remove Customs Info?">
              Clear the 4 customs info columns if you want to remove the customs
              info from a product.
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default ProductCustomsInfoImport
