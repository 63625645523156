import React from 'react'

const Footer = () => (
  <div className="mt-auto bg-gray-50 flex items-end w-full">
    <div className="bg-gray-100 mt-auto w-full">
      <div className="max-w-screen-xl mx-auto pb-8 pt-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 text-sm">
        <div className="flex justify-center md:order-2">
          <a
            className="text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@bctools.io"
          >
            support@bctools.io
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-sm leading-6 text-gray-400">
            &copy; {new Date().getFullYear()} Realm7. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
