import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UserContext } from './Context/User'

const Auth = ({ history }) => {
  const { authCode } = useParams()
  const user = useContext(UserContext)

  // Already logged in so redirect to tools
  useEffect(() => {
    if (user.authenticated) {
      window.setTimeout(() => history.push('/tools'), 2500)
      return
    } else if (user.isAuthPending) {
      return
    } else if (user.actionError || !authCode) {
      history.push('/login')
      return
    }
    user.login(authCode)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAuthPending, user.authenticated, history, authCode])

  return (
    <main className="bg-indigo-600 flex-1 flex min-h-[calc(100vh-64px-88px)]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-indigo-300 border-t border-indigo-500 flex items-center justify-center flex-1">
        <div>Logging you in. Prepare for the awesome...</div>
      </div>
    </main>
  )
}

export default Auth
