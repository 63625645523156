import React from 'react'
import ToolPageLayout from '../../ToolPageLayout'
import ProductOptionsV2DownloadAsync from './ProductOptionsV2DownloadAsync'

const ProductOptionsV2DownloadPage = () => {
  return (
    <ToolPageLayout>
      <ProductOptionsV2DownloadAsync />
    </ToolPageLayout>
  )
}

export default ProductOptionsV2DownloadPage
