import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import CustomerAddressesDownload from './CustomerAddressesDownload'
import CustomerAddressesImport from './CustomerAddressesImport'

export default function CustomerAddressesManagePage() {
  return (
    <>
      <ToolPageLayout2col>
        <CustomerAddressesDownload />
        <CustomerAddressesImport />
      </ToolPageLayout2col>
    </>
  )
}
