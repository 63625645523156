import React from 'react'
import { UserContext } from '../../Context/User'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  entities: 'items in store',
  updated: 'items updated',
  created: 'items created',
  deleted: 'items deleted',
}

const TestImport = () => {
  const user = React.useContext(UserContext)
  if (!user.props.isAdmin) {
    window.location = '/'
  }

  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: '_test import',
      method: '_test/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="TESTING">Internal testing</InfoNote>
            <InfoNote title="Developers only">
              Unless you are a BCtools developer, you should not be seeing this.
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default TestImport
