import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from "react-router-dom"
import { UserContext } from './Context/User'

const PrivateRoute = ({ component: Component, ...rest }) => {

  const user = useContext(UserContext)

  const { authenticated } = user

  useEffect(() => {
    const tryToRefreshAuth = async () => {
      await user.login()
    }
    if (!authenticated) {
      tryToRefreshAuth()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  if (user.authError) {
    return <Route {...rest} render={props => <Redirect to={{ pathname: '/', state: { destination: props.location } }} />} />
  }

  if (user.authenticated) {
    return <Route {...rest} render={props => <Component {...props} />} />
  }

  //return <Route {...rest} render={props => <div className="p-10">Sorry, Charlie. MEMBERS ONLY</div>} />
  return null

}

export default PrivateRoute

