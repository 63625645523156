import React from "react"
import SVG from './SVG'

const Error = ({ msg }) => {
  return (
    <div className="rounded-md bg-red-50 p-4 mt-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <SVG name="error" />
        </div>
        <div className="ml-3 text-sm leading-5 font-medium text-red-800">
          {msg?.message ? msg.message : msg}
        </div>
      </div>
    </div>
  )
}


export default Error