import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import BrandsDownload from './BrandsDownload'
import BrandsImport from './BrandsImport'

export default function BrandsManagePage() {
  return (
    <>
      <ToolPageLayout2col>
        <BrandsDownload />
        <BrandsImport />
      </ToolPageLayout2col>
    </>
  )
}
