import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'


const ScrollToAnchor = ({ children, location }) => {

    useEffect(
        () => {
            const element = document.getElementById(location.hash.replace("#", ""))

            setTimeout(() => {
                window.scrollTo({
                    behavior: element ? "smooth" : "auto",
                    top: element ? Math.max(element.offsetTop - 50, 0) : 0
                });
            }, 100);
        }, [location])


    return children || null
}

export default withRouter(ScrollToAnchor)