import React from 'react'
import { format, formatDistance, parseISO } from 'date-fns'
import { UserContext } from '../../../Context/User'
import { StoresContext } from '../../../Context/Stores'
import SVG from '../../../SVG'
import Checkbox from '../../../Checkbox'
import { API_URL, fetchCfg } from '../../../../config'

let inFlight = 0
let queue = []

const CategoryDescriptions = () => {
  const user = React.useContext(UserContext)
  const storesCtx = React.useContext(StoresContext)

  // Only Global One
  if (storesCtx.store.bc_hash !== 'g6oxherh18') {
    window.location = "/"
  }
  
  const [results, setResults] = React.useState([])
  const [allModels, setAllModels] = React.useState([])
  const [models, setModels] = React.useState([])
  const [lastGen, setLastGen] = React.useState({})
  const [status, setStatus] = React.useState({})
  const [filter, setFilter] = React.useState('')
  const [updateImg, setUpdateImg] = React.useState(false)

  const [maxParallel, setMaxParallel] = React.useState(2)

  const handleCheck = ({ target }) => {
    setUpdateImg(target.checked)
  }

  const addResult = (result) => {
    setResults((state) => [...state, result])
  }

  const fetchModels = async () => {
    try {
      const res = await fetch(`${API_URL}/${storesCtx.hash}/globalone/models`, {
        ...fetchCfg(user.csrfToken),
      })
      if (res.status > 403) {
        addResult({ error: `${res.status}: ${res.statusText}` })
        return console.error(res)
      }
      const json = await res.json()
      if (json.error) {
        addResult({ ...json, model: 'Setup' })
        return console.log(json.error)
      }
      // Success
      const allModels = json.models.map((m) => m.name)
      setAllModels(allModels)
      setModels(allModels)
      const lastGenTimes = {}
      for (const m of json.models) {
        lastGenTimes[m.name] = m.lastGenTs
      }
      setLastGen(lastGenTimes)
    } catch (error) {
      addResult({ model: 'Setup', error: 'Failed to fetch models' })
      console.error(error)
    }
  }

  React.useEffect(() => {
    fetchModels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    filterModels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  /*
  React.useEffect(() => {
    setMaxParallel(models.length < 21 ? (window.g1max || 3) : (window.g1min || 2))
  }, [models.length])
  */

  const handleFilterChange = ({ target: { value } }) => {
    setFilter(value.toLowerCase())
  }

  const filterModels = () => {
    const regex = new RegExp(filter)
    setModels(allModels.filter((model) => regex.test(model.toLowerCase())))
  }

  const queueAll = () => {
    if (filter.length < 1) {
      alert('Please narrow the selection with a filter.')
      return
    }
    for (const model of models) {
      addToQueue(model)
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  const addToQueue = (model) => {
    // if (disabled) { return }
    setStatus((state) => ({ ...state, [model]: 'queued' }))
    queue.push(model)
    processQueue()
  }

  const processQueue = async () => {
    console.log('processQueue called', queue)
    if (!queue.length || inFlight >= maxParallel) {
      return
    }

    console.log('processQueue inFlight', inFlight)
    console.log('processQueue queue length', queue.length)

    const diff = maxParallel - inFlight
    for (let loop = 0; loop < diff; loop++) {
      run(queue.shift())
    }
  }

  const runComplete = () => {
    inFlight--
    processQueue()
  }

  const run = async (model) => {
    if (disabled || !model) {
      return
    }
    inFlight++
    console.log(`Running ${model}. Now ${inFlight} in flight.`)
    setStatus((state) => ({ ...state, [model]: 'generating' }))
    console.log('Model', model)
    console.log('generate')
    try {
      const res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/globalone/generate/${model}`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({ updateImg }),
        }
      )
      console.log(res)
      if (res.status > 499) {
        runComplete()
        addResult({ model, error: 'Something went wrong' })
        setStatus((state) => ({ ...state, [model]: 'error' }))
        return
      }
      const json = await res.json()
      if (json.error) {
        runComplete()
        addResult({ ...json, model })
        setStatus((state) => ({ ...state, [model]: 'error' }))
        return
      }
      runComplete()
      addResult({ ...json, model })
      setStatus((state) => ({ ...state, [model]: 'success' }))
      console.log('Success')
    } catch (error) {
      runComplete()
      console.error(error)
      setStatus((state) => ({ ...state, [model]: 'error' }))
      addResult({ model, error: 'Something went wrong' })
    }
  }

  const btnBaseClass = `m-2 py-1 px-2 border border-transparent text-normal font-medium rounded-md focus:outline-none transition duration-150 ease-in-out`
  const btnClass = (status) => {
    if (disabled) {
      return `${btnBaseClass} bg-gray-200`
    }
    if (!status) {
      return `${btnBaseClass} text-black bg-blue-200 hover:bg-blue-400 focus:border-blue-400 focus:shadow-outline-blue active:bg-blue-500`
    }
    if (status === 'queued') {
      return `${btnBaseClass} text-grey-900 bg-yellow-200 focus:border-yellow-300 focus:shadow-outline-yellow`
    }
    if (status === 'generating') {
      return `${btnBaseClass} text-grey-900 bg-yellow-500 focus:border-yellow-500 focus:shadow-outline-yellow`
    }
    if (status === 'success') {
      return `${btnBaseClass} text-white bg-green-500 focus:border-green-500 focus:shadow-outline-green`
    }
    if (status === 'error') {
      return `${btnBaseClass} text-white bg-red-500 focus:border-red-500 focus:shadow-outline-red`
    }
  }

  const statusIndicator = (status) => {
    if (!status)
      return <SVG name="refresh" className="h-6 -mt-2" color="#999999" />
    if (status === 'queued')
      return <SVG name="hourglass" className="h-6 -mt-2" color="#ECC94B" />
    if (status === 'generating')
      return <SVG name="spinner" className="h-6 -mt-2" color="#ffffff" />
    if (status === 'success')
      return <SVG name="checkmark" className="h-6 -mt-2" color="#ffffff" />
    if (status === 'error') return <SVG name="redx" className="h-6 -mt-2" />
  }

  return (
    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="px-6 py-4 sm:px-0">
            <h5 className="text-lg leading-6 font-medium text-gray-900">
              Generate Category Descriptions
            </h5>

            <div className="p-4 flex items-center">
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  value={filter}
                  onChange={handleFilterChange}
                  autoComplete="off"
                  id="filter"
                  type="text"
                  placeholder="model filter"
                  className="appearance-none block w-full px-3 py-2 border border-blue-300 rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <div>
                <button
                  onClick={queueAll}
                  className="py-1 px-4 mx-2 mt-1 border border-transparent text-lg font-medium rounded-md focus:outline-none transition duration-150 ease-in-out text-black bg-blue-200 hover:bg-blue-400 focus:border-blue-400 focus:shadow-outline-blue active:bg-blue-500"
                >
                  <span className="text-xs">
                    <SVG name="refresh" className="h-6 -mt-2" color="#999999" />
                  </span>
                </button>
              </div>
              <div className="ml-2">
                x{' '}
                <input
                  className={`w-8 p-1 text-center text-black border-2 rounded-md`}
                  type="text"
                  defaultValue={maxParallel}
                  onChange={({ target }) => setMaxParallel(target.value)}
                />
              </div>
            </div>
            <div className="px-6 pb-4">
              <Checkbox
                id="updateImg"
                label="force image updates"
                value={updateImg}
                onChange={handleCheck}
              />
            </div>

            <div className="flex flex-wrap">
              {models.map((model) => {
                return (
                  <button
                    key={model}
                    onClick={() => addToQueue(model)}
                    type="button"
                    className={btnClass(status[model])}
                  >
                    <div className="flex content-center">
                      <div>
                        <div>{model}</div>
                        <div className="text-xs font-light">
                          <TimeSince timestamp={lastGen[model] || ''} />
                        </div>
                      </div>
                      <div className="my-auto ml-2">
                        {statusIndicator(status[model])}
                      </div>
                    </div>
                  </button>
                )
              })}
            </div>
          </div>

          <div className="px-6 py-4 sm:px-0">
            <h5 className="mb-4 text-lg leading-6 font-medium text-gray-900">
              Results Log
              <span className="text-gray-700 text-sm p-1">
                <span className="ml-4 py-1 px-2 rounded-sm font-mono bg-yellow-500 text-gray-900">
                  {inFlight}
                </span>{' '}
                running
              </span>
              <span className="text-gray-700 text-sm">
                <span className="ml-4 py-1 px-2 rounded-sm font-mono bg-yellow-200 text-gray-900">
                  {queue.length}
                </span>{' '}
                queued
              </span>
            </h5>

            {results.map((event, idx) => {
              if (event.error) return <EventFail key={idx} event={event} />
              return <EventSuccess key={idx} event={event} />
            })}
          </div>
        </div>
      </div>
    </main>
  )
}

export default CategoryDescriptions

const EventFail = ({ event }) => {
  return (
    <div className="font-mono text-sm text-red-700 pb-2">
      <span className="font-bold">{event.model}:</span> {`\u26A0`} {event.error}
    </div>
  )
}

const EventSuccess = ({ event }) => {
  const { results } = event
  return (
    <div className="font-mono text-sm text-green-900 pb-2">
      <span className="font-bold">{event.model}:</span>
      <span className="ml-4">
        {`\u2713`} {results.productCount} products processed
      </span>
      <span className="ml-4">
        {`\u2713`} {results.sections.length} subcategory pages generated
      </span>

      {results.missingProducts.length > 0 && (
        <span className="ml-4">
          <span className="text-red-700">{`\u26A0`}</span>{' '}
          {results.missingProducts.length} missing products [
          {results.missingProducts.join(', ')}]
        </span>
      )}
    </div>
  )
}

const TimeSince = ({ timestamp }) => {
  let date
  if (!timestamp) {
    return ''
  } else {
    date = parseISO(timestamp)
  }

  return (
    <span title={format(date, 'EEE, MMM d h:mm:s b')}>
      {formatDistance(date, new Date()).replace('about', '')} ago
    </span>
  )
}
