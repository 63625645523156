import React from 'react'
import StoreForm from './StoreForm'
import SVG from './SVG'

const Stores = ({ location, history }) => {
  return (
    <main className="bg-gray-100 flex-1">
      <div className="bg-indigo-600">
        <div className="max-w-7xl mx-auto h-40 px-4 sm:px-6 lg:px-8 text-indigo-300 text-5xl font-semibold border-t border-indigo-500"></div>
      </div>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8 -mt-24 md:flex md:space-x-6 lg:space-x-8">
        <div className="md:w-1/3">
          <StoreForm />
          <div className="bg-gray-200 rounded-lg mt-6 lg:mt-8 p-4 flex flex-col items-center">
            <SVG name="shield" className="w-10 h-10 text-gray-500" />
            <p className="pt-1 text-gray-600 text-center text-xs max-w-xs">
              We pinky promise to keep your credentials extra secure. You can
              revoke the API credentials any time using your BigCommerce control
              panel.
            </p>
          </div>
          <div className="my-4">
            <h3 className="mb-2 text-md font-semibold text-gray-900">
              Create your API access with these privileges
            </h3>
            <div className="py-2">
              <img src="/img/required-privs.png" alt="OAuth scopes"/>
            </div>
          </div>
        </div>
        <div className="md:w-2/3">
          <div className="bg-white rounded-lg p-6">
            <h3 className="mb-2 text-lg font-semibold text-gray-900">
              How to add your BigCommerce store
            </h3>

            <p>
              Watch this video to see how you can get the information needed to
              connect to your store. Don’t worry, you only need to do this once.
              <span className="font-bold"> NOTE: </span> See the list of
              privs on this page for the up-to-date list of privs required to use the tools.
            </p>

            <div
              className="mt-8 overflow-hidden relative h-0 rounded-lg"
              style={{ paddingBottom: '56.25%' }}
            >
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/759743877?h=12b9afb7af&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  title="BCtools.io Add Store"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
            <div className="hidden p-8">
              <img
                src="/img/video-thumb.jpg"
                alt="video"
                className="mt-8 rounded-2xl"
              />
            </div>
            <p className="text-base leading-6 text-gray-700 pt-4">
              A BigCommerce documentation article about{' '}
              <a
                className="link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://developer.bigcommerce.com/api-docs/getting-started/authentication/rest-api-authentication#obtaining-store-api-credentials"
              >
                creating API credentials
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Stores
