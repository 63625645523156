import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import { createFileName, downloadBlob } from '../../../common'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible()

const CustomerAddressesDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { store } = storesCtx

  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Customer addresses download')
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(
        `${API_URL}/${store.bc_hash}/customer-addresses/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
        }
      )
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName('customer-addresses', store.bc_hash, 'csv')
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.log({res, err})
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="addresses" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">Customer Addresses</div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="pt-4">
          <div className="font-bold text-lg">Download addresses</div>
          <div className="text-sm text-gray-500">
            {/* Nothing to see here */}
          </div>
        </div>

        {error && <Error msg={error} />}

        <div className="mt-2">
          <div className="w-full py-4">
            <button
              onClick={runTool}
              disabled={disabled}
              type="button"
              className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                disabled ? 'bg-gray-500' : 'bg-blue-600'
              } ${
                !disabled && 'hover:bg-blue-500'
              } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
            >
              {waiting ? (
                <SVG name="spinner" size={32} />
              ) : (
                <SVG name="download" size={32} lineWidth={1} />
              )}
              <div className="ml-2">Download</div>
            </button>
          </div>
        </div>

        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                It may take several seconds for your CSV file to be generated
                and start downloading.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CustomerAddressesDownload
