import React from 'react'

const ProgressBar = ({
  importProgress,
  validationProgress,
  uploadProgress,
}) => {
  const label = () => {
    if (uploadProgress < 100) {
      return 'Upload'
    }
    if (validationProgress < 100) {
      return 'Validation'
    }
    return 'Import'
  }

  const progress = () => {
    if (uploadProgress < 100) {
      return uploadProgress
    }
    if (validationProgress < 100) {
      return validationProgress
    }
    return importProgress
  }

  return (
    <>
      <div className="mt-2 pb-2 w-full flex justify-between">
        <span>{label()} Progress</span>
        {/*
      <a href="#cancel" className="text-gray-400 hover:text-gray-800">
        <SVG name="close" size={20} lineWidth={1.5} />
      </a>
      */}
      </div>
      <div className=" rounded-full bg-gray-200 shadow-inner">
        <div
          className="h-3 rounded-full bg-green-500 transition animate-pulse"
          style={{
            width: `${progress()}%`,
          }}
        ></div>
      </div>
    </>
  )
}
export default ProgressBar
