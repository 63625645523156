import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import {
  createFileName,
  downloadBlob,
  fromLocalStorage,
  createFieldArray,
} from '../../../common'
import Plausible from 'plausible-tracker'
import useCategoriesFilter from '../../export/useCategoriesFilter'
import useBrandsFilter from '../../export/useBrandsFilter'

const { trackEvent } = Plausible()

const STORAGE_KEY = 'bulkPricingOptions'

const PRODUCT_PROPS_TO_UPDATE = [
  'cost_price',
  'map_price',
  'retail_price',
  'sale_price',
  'price',
  'inventory_level',
]

const productProps = {
  sale_price: false,
  retail_price: false,
  cost_price: false,
  map_price: false,
  inventory_level: false,
  sku: true,
  name: true,
  upc: false,
  mpn: false,
  gtin: false,
  total_sold: false,
  categories: false,
  brand_id: false,
}

const BulkPricingDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { categoriesPanel, selectedCategories } = useCategoriesFilter()
  const { brandsPanel, selectedBrands } = useBrandsFilter()

  /* "Remember" the user's seclections */
  const getOptionDefaultsFromLocalStorage = () => {
    const savedOptions = fromLocalStorage(STORAGE_KEY) || {}
    return {
      productProps: savedOptions.productProps || createFieldArray(productProps),
    }
  }

  const [formData, setFormData] = useState(getOptionDefaultsFromLocalStorage())
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const handleArrayItemToggle = (arrayName, key) => {
    const field = formData[arrayName]
    const newField = field.includes(key)
      ? field.filter((item) => item !== key)
      : [...field, key]
    setFormData((formData) => ({
      ...formData,
      [arrayName]: newField,
    }))
    setError(null)
  }

  const handleProductColumnToggle = (event) => {
    handleArrayItemToggle('productProps', event?.target?.id)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Bulk pricing download')
    console.log(formData)
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      console.log('fetching!')
      res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/bulk-pricing/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({
            ...formData,
            categories: selectedCategories.map((e) => e.id),
            brands: selectedBrands.map((e) => e.id),
          }),
        }
      )
      if (res.status !== 200) {
        throw new Error(res.status)
      }
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName('bulk-pricing', storesCtx.store.bc_hash, 'csv')
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="price" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">Bulk Pricing Rules</div>
        </h5>
        <div className="pl-16 pb-4">
          <a
            className="link"
            target="_blank"
            rel="noreferrer"
            href="https://support.bigcommerce.com/s/article/Bulk-Pricing"
          >
            BigCommerce Bulk Pricing Rules documentation
          </a>
        </div>
        <div className="pl-16 pb-4">
          <p className="text-md">
            Upon import, in addition to your bulk pricing rules, the product props 
            below that have an asterisk (*) will be updated if they are different
            than what is in your store.
          </p>
        </div>
      </div>

      <>
        <form
          method="post"
          action="#"
          onSubmit={handleSubmit}
          className="flex flex-col"
        >
          <div className="flex flex-1 pt-6">
            <div className="pr-4 pt-4 pb-6 flex-1">
              <div>
                <div className="font-bold text-lg">Select fields</div>
                <div className="text-sm text-gray-500">
                  Product fields to include in download
                </div>
              </div>
              <div className="mt-6">
                <Checkbox
                  key="product_id"
                  id="product_id"
                  value={true}
                  disabled={true}
                />
                <Checkbox key="price" id="price" value={true} disabled={true} asterisk={true} />
                {Object.keys(productProps).map((id) => (
                  <Checkbox
                    key={id}
                    id={id}
                    value={formData.productProps.includes(id)}
                    onChange={handleProductColumnToggle}
                    asterisk={PRODUCT_PROPS_TO_UPDATE.includes(id)}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              {categoriesPanel}
              {brandsPanel}
            </div>
          </div>

          {error && <Error msg={error} />}
          <div className="mt-2">
            <span className="block w-full py-4">
              <button
                onClick={runTool}
                disabled={disabled}
                type="button"
                className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                  disabled ? 'bg-gray-500' : 'bg-blue-600'
                } ${
                  !disabled && 'hover:bg-blue-500'
                } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
              >
                {waiting ? (
                  <SVG name="spinner" size={32} />
                ) : (
                  <SVG name="download" size={32} lineWidth={1} />
                )}
                <div className="ml-2">Download</div>
              </button>
            </span>
          </div>
          {disabled ? (
            <div className="bg-yellow-50 px-4 py-2 rounded-md">
              <div className="flex items-center">
                <span className="text-yellow-600">
                  <SVG name="warning" size={24} />
                </span>
                <div className="ml-3 ">
                  <p className="text-sm text-yellow-600">
                    You must be logged in and have selected a store before you
                    can use this tool.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-1 py-2 rounded-md opacity-70">
              <div className="flex items-center">
                <span className="text-gray-500">
                  <SVG name="info" size={16} />
                </span>
                <div className="ml-3">
                  <p className="text-xs text-gray-500">
                    It may take several seconds for your CSV file to be
                    generated and start downloading.
                  </p>
                </div>
              </div>
            </div>
          )}
        </form>
      </>
    </div>
  )
}

export default BulkPricingDownload
