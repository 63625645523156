import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import BulkPricingDownload from './BulkPricingDownload'
import BulkPricingImport from './BulkPricingImport'

export default function BulkPricingManagePage() {
  return (
    <ToolPageLayout2col>
      <BulkPricingDownload />
      <BulkPricingImport />
    </ToolPageLayout2col>
  )
}
