import React, { useState, useContext } from 'react'
import { UserContext } from '../Context/User'
import Error from '../Error'

const SignupForm = ({ priceType }) => {
  const user = useContext(UserContext)
  const [formData, setFormData] = useState({
    email: user.props.email || '',
    priceType,
  })
  const [formDirty, setFormDirty] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  const handleChange = (event) => {
    const { id, value } = event?.target
    setFormData((formData) => ({ ...formData, [id]: value }))
    if (!formDirty) {
      setFormDirty(true)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      const result = await user.signup(formData)
      if (result.redirect) {
        window.location = result.redirect
        return
      }
      // showLogin(formData.email)
    } catch (error) {
      setError(error.message)
    } finally {
      setIsSubmitting(false)
      setFormDirty(false)
    }
  }

  return (
    <div>
      <div className="py-8 px-4 shadow sm:rounded-lg sm:px-10 bg-blue-100">
        <form method="post" action="#" onSubmit={handleSubmit}>
          {user.props.email ? (
            <div>{user.props.email}</div>
          ) : (
            <>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>


            </>
          )}

          {error && <Error msg={error} />}

          <div className="mt-6">
            <div className="rounded-md shadow">
              <button
                disabled={!user.props.email && (isSubmitting || !formDirty)}
                type="submit"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignupForm
