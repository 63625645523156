import React from 'react'

const Results = ({ resultMap, result = {} }) => {

  const labels = {
    entities: 'entities in store',
    rows_in_file: 'rows in CSV',
    validated: 'rows validated',
    processed: 'rows processed',
    updated: 'updated',
    created: 'created',
    deleted: 'deleted',
    ...resultMap,
  }

  const keys = Object.keys(labels).filter((key) => resultMap[key] !== false)

  const Item = ({ resultKey }) => (
    <>
      <div className="text-right">{result[resultKey] || 0}</div>
      <div className="text-gray-500">{labels[resultKey]}</div>
    </>
  )

  return (
    <div className="flex flex-col flex-1">
      <div className="mt-4 p-4 bg-gray-100 rounded-lg flex-1 mb-4">
        <div className="mb-4 text-sm">{result.status}</div>
        <div className="grid grid-cols-[auto_1fr] gap-x-2">
          {keys.map((key) => (
            <Item key={key} resultKey={key} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Results
