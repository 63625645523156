import useEntityFilter from './useEntityFilter'

const useBrandsFilter = () => {
  const { filterPanel, selectedEntities } = useEntityFilter('brands')

  return {
    brandsPanel: filterPanel,
    selectedBrands: selectedEntities,
  }
}

export default useBrandsFilter
