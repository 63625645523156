import React, { useContext, useState } from 'react'
import Plausible from 'plausible-tracker'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import { createFileName, downloadBlob, fromLocalStorage } from '../../../common'
import InfoNote from '../../InfoNote'
import useCategoriesFilter from '../../export/useCategoriesFilter'
import useBrandsFilter from '../../export/useBrandsFilter'

const { trackEvent } = Plausible()

const STORAGE_KEY = 'productsOptionsA'

/* Engineering ticket CATALOG-6655
gift_wrapping_options_type: false,
gift_wrapping_options_list: false,
*/

const productProps = {
  availability: true,
  availability_description: false,
  base_variant_id: false,
  bin_picking_number: false,
  brand_id: true,
  calculated_price: false,
  categories: true,
  condition: false,
  cost_price: true,
  custom_url: false,
  date_created: false,
  date_modified: false,
  depth: false,
  description: false,
  fixed_cost_shipping_price: false,
  gtin: true,
  height: false,
  inventory_level: true,
  inventory_tracking: false,
  inventory_warning_level: false,
  is_condition_shown: false,
  is_featured: false,
  is_free_shipping: false,
  is_preorder_only: false,
  is_price_hidden: false,
  is_visible: true,
  map_price: false,
  meta_description: false,
  meta_keywords: false,
  mpn: true,
  name: true,
  open_graph_description: false,
  open_graph_title: false,
  open_graph_type: false,
  open_graph_use_image: false,
  open_graph_use_meta_description: false,
  open_graph_use_product_name: false,
  option_set_display: false,
  option_set_id: false,
  order_quantity_maximum: false,
  order_quantity_minimum: false,
  page_title: true,
  preorder_message: false,
  preorder_release_date: false,
  price: true,
  price_hidden_label: false,
  product_tax_code: false,
  related_products: false,
  retail_price: true,
  reviews_count: false,
  reviews_rating_sum: false,
  sale_price: true,
  search_keywords: false,
  sku: true,
  sort_order: false,
  tax_class_id: false,
  total_sold: false,
  type: true,
  upc: true,
  view_count: false,
  warranty: false,
  weight: false,
  width: false,
}

const otherOptions = {
  includeVariants: false,
}

// Convert a group of checkbox props to a simple array of names.
// If the name is in the array, the checkbox is checked.
const createFieldArray = (props) =>
  Object.entries(props)
    .map(([key, val]) => (val ? key : null))
    .filter(Boolean)

const ProductsDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { categoriesPanel, selectedCategories } = useCategoriesFilter()
  const { brandsPanel, selectedBrands } = useBrandsFilter()

  /* "Remember" the user's seclections */
  const getOptionDefaultsFromLocalStorage = () => {
    const savedOptions = fromLocalStorage(STORAGE_KEY) || { productProps: [] }

    // TODO: can eventually remove this
    savedOptions.productProps = savedOptions.productProps.filter(
      (p) => p !== 'layout_file'
    )

    return {
      productProps: createFieldArray(productProps),
      ...otherOptions,
      ...savedOptions,
    }
  }

  const [formData, setFormData] = useState(getOptionDefaultsFromLocalStorage())
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const handleArrayItemToggle = (arrayName, key) => {
    const field = formData[arrayName]
    const newField = field.includes(key)
      ? field.filter((item) => item !== key)
      : [...field, key]
    setFormData((formData) => ({
      ...formData,
      [arrayName]: newField,
    }))
  }

  const handleProductColumnToggle = (event) => {
    handleArrayItemToggle('productProps', event?.target?.id)
  }

  const handleCheck = (event) => {
    const { id, checked } = event?.target
    setFormData((formData) => ({ ...formData, [id]: checked }))
    setError(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Products download')
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/products/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({
            ...formData,
            categories: selectedCategories.map((e) => e.id),
            brands: selectedBrands.map((e) => e.id),
          }),
        }
      )
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName('products', storesCtx.store.bc_hash, 'csv')
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="product" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-center">
            Download Products{' '}
            <span className="hidden ml-2 px-4 py-1 text-sm text-white bg-yellow-500 rounded-lg">
              BETA
            </span>
          </div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="pt-4">
          <div className="text-sm text-gray-500">
            Select the columns you want to see in your CSV file.
          </div>
        </div>

        <div className="mt-6 columns-1 md:columns-2 lg:columns-3">
          <Checkbox
            key="id"
            id="id"
            value={true}
            onChange={handleCheck}
            disabled={true}
            className="inline-block"
          />
          {Object.keys(productProps).map((id) => (
            <Checkbox
              key={id}
              id={id}
              value={formData.productProps.includes(id)}
              onChange={handleProductColumnToggle}
              className="inline-block"
            />
          ))}
        </div>

        <div className="-mx-4 mt-6 p-4 bg-gray-100 rounded-md">
          <div>
            <div className="mt-6 md:mt-0 text-md font-bold flex">
              Variants
              <span className="ml-2 px-2 text-sm text-white font-semibold bg-orange-500 rounded flex items-center">
                BETA
              </span>
            </div>
            <div className="mt-2">
              <Checkbox
                id="includeVariants"
                label="Include variants"
                value={formData.includeVariants}
                onChange={handleCheck}
              />
              <InfoNote title="Can't import Variants">
                Do not include variants if you intend to import product changes.
                Variant import not yet supported.
              </InfoNote>
            </div>
          </div>
        </div>

        <div className="-mx-4 mt-4 p-4 bg-gray-100 rounded-md flex">
          {categoriesPanel}
          {brandsPanel}
        </div>

        {error && <Error msg={error} />}

        <div className="mt-2">
          <span className="block w-full py-4">
            <button
              onClick={runTool}
              disabled={disabled}
              type="button"
              className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                disabled ? 'bg-gray-500' : 'bg-blue-600'
              } ${
                !disabled && 'hover:bg-blue-500'
              } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
            >
              {waiting ? (
                <SVG name="spinner" size={32} />
              ) : (
                <SVG name="download" size={32} lineWidth={1} />
              )}
              <div className="ml-2">Download</div>
            </button>
          </span>
        </div>
        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                It may take several seconds for your CSV file to be generated
                and start downloading.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductsDownload
