import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import CustomerGroupsCategoryDownload from './CustomerGroupsCategoryDownload'
import CustomerGroupsCategoryImport from './CustomerGroupsCategoryImport'

export default function CustomerGroupsCategoriesManagePage() {
  return (
    <ToolPageLayout2col>
      <CustomerGroupsCategoryDownload />
      <CustomerGroupsCategoryImport />
    </ToolPageLayout2col>
  )
}
