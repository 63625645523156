import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import ScrollToAnchor from './ScrollToAnchor'
import Header from './Header'
import Footer from './Footer'
import Home from './Home'
import Login from './Login'
import Auth from './Auth'
import SubscribeSuccess from './SubscribeSuccess'
import Member from './Member'
import Stores from './Stores'
import Pricing from './Pricing'
import Signup from './Signup'
import Terms from './Terms'
import Privacy from './Privacy'
import NotFound from './NotFound'

// Tools
import BrandsManagePage from './tools/brands/BrandsManagePage'

import CategoriesManagePage from './tools/categories/CategoriesManagePage'
import CustomerGroupsCategoriesManagePage from './tools/customerGroupsCategories/CustomerGroupsCategoriesManagePage'

import CustomFieldsManagePage from './tools/product-custom-fields/CustomFieldsManagePage'
import BulkPricingManagePage from './tools/bulk-pricing/BulkPricingManagePage'

import ProductTemplatesManagePage from './tools/templates/ProductTemplatesManagePage'
import BrandTemplatesManagePage from './tools/templates/BrandTemplatesManagePage'
import CategoryTemplatesManagePage from './tools/templates/CategoryTemplatesManagePage'

import ProductsCustomsInfoManagePage from './tools/product-customs-info/ProductsCustomsInfoManagePage'

import CouponsDownloadPage from './tools/coupons/CouponsDownloadPage'
import GiftCertificatesManagePage from './tools/giftcertificates/GiftCertificatesManagePage'
import OrdersDownloadPage from './tools/orders/OrdersDownloadPage'

import ProductReviewsManagePage from './tools/product-reviews/ProductReviewsManagePage'

import ProductsManagePage from './tools/products/ProductsManagePage'
import VariantsDownloadPage from './tools/variants/VariantsDownloadPage'
import VariantsManagePage from './tools/variants/VariantsManagePage'

import ProductImagesManagePage from './tools/product-images/ProductImagesManagePage'

import RedirectsManagePage from './tools/redirects/RedirectsManagePage'

import PagesManagePage from './tools/pages/PagesManagePage'

import ProductOptionsManagePage from './tools/product-options/ProductOptionsManagePage'
import ProductOptionsV2DownloadPage from './tools/v2/product-options/ProductOptionsV2DownloadPage'

import ProductAdminOneClick from './tools/ProductAdminOneClick'

import CustomerAddressesManagePage from './tools/customer-addresses/CustomerAddressesManagePage'

// Developer-only testing tool
import TestManagePage from './tools/_test/TestManagePage'

// Custom Tools
import GlobalOneTechnology_CategoryDescriptions from './tools/custom/GlobalOneTechnology/CategoryDescriptions'
import SinnFein_OrdersDownloadPage from './tools/custom/SinnFein/OrdersDownloadPage'

// Some FA tricks: https://www.npmjs.com/package/@fortawesome/react-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCheckSquare, faCoffee)

function App() {
  return (
    <ScrollToAnchor>
      <div className="h-full flex flex-col min-h-screen">
        <Header />
        <>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/subscribe-success"
              component={SubscribeSuccess}
            />
            <Route exact path="/login/" component={Login} />
            <Route exact path="/auth/:authCode?" component={Auth} />

            <Route exact path="/tools" component={Member} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/signup/:priceType?" component={Signup} />
            <PrivateRoute exact path="/stores" component={Stores} />

            {/* Brands */}
            <Route exact path="/tools/brands" component={BrandsManagePage} />

            {/* Categories */}
            <Route
              exact
              path="/tools/categories"
              component={CategoriesManagePage}
            />

            {/* Customer Groups & Categories */}
            <Route
              exact
              path="/tools/customer-groups-categories"
              component={CustomerGroupsCategoriesManagePage}
            />

            {/* Custom Fields */}
            <Route
              exact
              path="/tools/products/custom-fields"
              component={CustomFieldsManagePage}
            />

            {/* Bulk pricing */}
            <Route
              exact
              path="/tools/products/bulk-pricing"
              component={BulkPricingManagePage}
            />

            {/* Customs Info */}
            <Route
              exact
              path="/tools/products/customs-info"
              component={ProductsCustomsInfoManagePage}
            />

            {/* Templates */}
            <Route
              exact
              path="/tools/products/templates"
              component={ProductTemplatesManagePage}
            />
            <Route
              exact
              path="/tools/brands/templates"
              component={BrandTemplatesManagePage}
            />
            <Route
              exact
              path="/tools/categories/templates"
              component={CategoryTemplatesManagePage}
            />
            <Route
              exact
              path="/tools/coupons/download"
              component={CouponsDownloadPage}
            />
            <Route
              exact
              path="/tools/giftcertificates"
              component={GiftCertificatesManagePage}
            />

            <Route exact path="/tools/orders" component={OrdersDownloadPage} />

            <Route
              exact
              path="/tools/product-reviews"
              component={ProductReviewsManagePage}
            />
            <Route
              exact
              path="/tools/products"
              component={ProductsManagePage}
            />
            <Route
              exact
              path="/tools/variants"
              component={VariantsManagePage}
            />
            <Route
              exact
              path="/tools/variants-with-import"
              component={VariantsManagePage}
            />
            <Route
              exact
              path="/tools/product-images"
              component={ProductImagesManagePage}
            />
            <Route
              exact
              path="/tools/redirects"
              component={RedirectsManagePage}
            />
            <Route exact path="/tools/pages" component={PagesManagePage} />
            <Route
              exact
              path="/tools/product-options"
              component={ProductOptionsManagePage}
            />
            <Route
              exact
              path="/tools/v2/product-options"
              component={ProductOptionsV2DownloadPage}
            />
            <Route
              exact
              path="/tools/customer-addresses"
              component={CustomerAddressesManagePage}
            />

            <Route exact path="/tools/_test" component={TestManagePage} />

            <Route
              exact
              path="/tools/product-admin-one-click"
              component={ProductAdminOneClick}
            />
            <Route
              exact
              path="/tools/custom/GlobalOneTechnology/category-descriptions"
              component={GlobalOneTechnology_CategoryDescriptions}
            />

            <Route
              exact
              path="/tools/custom/SinnFein/orders"
              component={SinnFein_OrdersDownloadPage}
            />

            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route component={NotFound} />
          </Switch>
        </>
        <Footer />
      </div>
    </ScrollToAnchor>
  )
}

export default App
