import { format } from 'date-fns'
import * as React from 'react'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import InfoNote from '../../InfoNote'
import { createFileName, downloadBlob, fromLocalStorage } from '../../../common'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible()

const columns = {
  'Order ID': true,
  'Created At': true,
  Status: true,
  'Shipment Method': true,
  'Shipping Company': true,
  'Shipping First Name': true,
  'Shipping Last Name': true,
  'Shipping Street 1': true,
  'Shipping Street 2': true,
  'Shipping City': true,
  'Shipping State': true,
  'Shipping Country': true,
  'Shipping Country Code': true,
  'Shipping Zip': true,
  'Customer Phone': true,
  'Customer Email': true,
  'Order Total (exc tax)': true,
  'Order Total (inc tax)': true,
  'Shipping Cost (inc tax)': true,
  'Currency Code': true,
  Product: true,
  SKU: true,
  Options: true,
  Quantity: true,
  'Unit Price': true,
  'Unit Weight': true,
  'International Shipping': true,
  'Harmonisation Code': true,
  'Country of Origin': true,
  'Commodity Description': true,
  'Staff notes': true,
  'Customer Message': true,
}

const requiredColumns = ['Order ID', 'Product']

const OrdersDownload = () => {
  const user = React.useContext(UserContext)
  const storesCtx = React.useContext(StoresContext)
  const { store } = storesCtx

  /* "Remember" the user's column seclections */
  const getColumnDefaultsFromLocalStorage = () => {
    const savedColumns = fromLocalStorage('ordersColumns') || {}
    return {
      ...columns,
      ...savedColumns,
    }
  }

  const [waiting, setWaiting] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [formData, setFormData] = React.useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    ...getColumnDefaultsFromLocalStorage(),
  })

  const onChange = (e) => {
    const { target } = e
    setFormData((formData) => ({
      ...formData,
      [target.name]: target.value,
    }))
  }

  const onCheck = (event) => {
    const { id, checked } = event?.target
    setFormData((formData) => ({ ...formData, [id]: checked }))
    setError(null)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Orders download')
    localStorage.setItem('ordersColumns', JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(`${API_URL}/${store.bc_hash}/orders/export`, {
        ...fetchCfg(user.csrfToken),
        method: 'post',
        body: JSON.stringify(formData),
      })
      if (res.status === 204) {
        setError('No orders found')
        return
      }
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(fileBlob, createFileName('orders', store.bc_hash, 'csv'))
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="order" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-center">
            Orders
            <span className="ml-2 px-4 py-1 text-sm text-white bg-yellow-500 rounded-lg">
              BETA
            </span>
          </div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={onSubmit}>
        <div className="pt-4">
          <div className="text-sm text-gray-500">
            Select the columns you want to see in your CSV file.
          </div>
          <InfoNote title="Be patient">
            It can take up to about 2 seconds per order to create your file.
          </InfoNote>
        </div>

        {error && <Error msg={error} />}

        {/* Date pickers  */}
        <div>
          <div className="my-4">
            Include all orders between and including these dates
          </div>
          <div className="inline-block">
            <input
              className="appearance-none block px-3 py-2 border border-blue-300 rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              name="startDate"
              type="date"
              value={formData.startDate}
              onChange={onChange}
            />
          </div>
          <div className="mx-4 inline-block">to</div>
          <div className="inline-block">
            <input
              className="appearance-none block px-3 py-2 border border-blue-300 rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              name="endDate"
              type="date"
              value={formData.endDate}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-2">
          {Object.keys(columns).map((id) => (
            <Checkbox
              key={id}
              id={id}
              value={formData[id]}
              onChange={onCheck}
              disabled={requiredColumns.includes(id)}
            />
          ))}
        </div>

        <div className="mt-2 flex items-center  h-60">
          <button
            onClick={runTool}
            disabled={disabled}
            type="button"
            className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
              disabled ? 'bg-gray-500' : 'bg-blue-600'
            } ${
              !disabled && 'hover:bg-blue-500'
            } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
          >
            {waiting ? (
              <SVG name="spinner" size={32} />
            ) : (
              <SVG name="download" size={32} lineWidth={1} />
            )}
            <div className="ml-2">Download</div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default OrdersDownload
