import React from 'react'
import { Link } from 'react-router-dom'
import { StoresContext } from './Context/Stores'
import { UserContext } from './Context/User'
import SVG from './SVG'

const Tools = ({ location, history }) => {
  const user = React.useContext(UserContext)

  const allowBeta =
    user.props.allowBeta ||
    [
      'dev@realm7.com',
      'troy@troywolf.com',
      // Amandeep Singh
      'contact@aman.dev',
      // Paul Miller
      'support@proofdigital.com',
      // 'nicholas@simbaline.com',
      // Peter Noonan
      'peter@industrialfansdirect.com',
    ].includes(user.props.email)

  const storesCtx = React.useContext(StoresContext)
  const { store } = storesCtx

  return (
    <ul className="p-0 pb-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
      <Link to="/tools/products/bulk-pricing">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="price" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Bulk Pricing</h3>
          <p className="small">Edit your bulk pricing rules</p>
        </li>
      </Link>

      <Link to="/tools/products/custom-fields">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="swatch" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Custom Fields</h3>
          <p className="small">Bulk-edit your custom fields</p>
        </li>
      </Link>

      <Link to="/tools/categories">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="categories" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">
            Categories{' '}
            {store?.summary && (
              <>({store.summary.categories_count?.toLocaleString()})</>
            )}
          </h3>
          <p className="small">Bulk-edit your categories</p>
        </li>
      </Link>

      {/* Tool originally built for Ford Hotel Supply */}
      <Link to="/tools/customer-groups-categories">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="customerGroup" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Customer Group Categories</h3>
          <p className="small">
            Bulk-edit your customer group category assignments
          </p>
        </li>
      </Link>

      <Link to="/tools/brands">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="brands" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">
            Brands{' '}
            {store?.summary && (
              <>({store.summary.brands_count?.toLocaleString()})</>
            )}
          </h3>
          <p className="small text-gray-600">Bulk-edit your brands</p>
        </li>
      </Link>

      <Link to="/tools/products">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="product" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Products</h3>
          <p className="small">Bulk-edit your products</p>
        </li>
      </Link>

      {/* Simbaline beta preview */}
      {(allowBeta ||
        ['57q61vb7d4', 'e0z9pc3auy', '6b2ngdkl4m'].includes(store.bc_hash)) && (
        <Link to="/tools/product-images">
          <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
            <span className="absolute top-3 right-3 px-2 text-sm text-white font-semibold bg-orange-500 rounded flex items-center">
              BETA
            </span>
            <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
              <SVG name="image" size={62} lineWidth={1.5} />
            </div>
            <h3 className="mt-4 font-semibold">Product Images</h3>
            <p className="small">Bulk-edit your product images</p>
          </li>
        </Link>
      )}

      {/* Simbaline beta preview */}
      {(allowBeta ||
        ['57q61vb7d4', 'e0z9pc3auy', '6b2ngdkl4m'].includes(store.bc_hash)) && (
        <Link to="/tools/variants">
          <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
            <span className="absolute top-3 right-3 px-2 text-sm text-white font-semibold bg-orange-500 rounded flex items-center">
              BETA
            </span>
            <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
              <SVG name="variant" size={62} lineWidth={1.5} />
            </div>
            <h3 className="mt-4 font-semibold">Variants</h3>
            <p className="small">Download your variants</p>
          </li>
        </Link>
      )}

      <Link to="/tools/product-options">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="options" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Product Options</h3>
          <p className="small text-gray-600">
            Download & bulk-edit your product options that define your variants
          </p>
        </li>
      </Link>

      {/* Realm7 beta preview */}
      {(allowBeta || ['57q61vb7d4', 'hmffx969ez'].includes(store.bc_hash)) && (
        <Link to="/tools/v2/product-options">
          <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
            <span className="absolute top-3 right-3 px-2 text-sm text-white font-semibold bg-orange-500 rounded flex items-center">
              BETA
            </span>
            <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
              <SVG name="options" size={62} lineWidth={1.5} />
            </div>
            <h3 className="mt-4 font-semibold">V2 Product Options</h3>
            <p className="small">Download your V2 Product Options</p>
          </li>
        </Link>
      )}

      <Link to="/tools/products/customs-info">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="world" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Product Customs Info</h3>
          <p className="small">
            Bulk-edit your customs info used to ship your products
            internationally
          </p>
        </li>
      </Link>

      <Link to="/tools/orders">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <span className="absolute top-3 right-3 px-2 text-sm text-white font-semibold bg-orange-500 rounded flex items-center">
            BETA
          </span>
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="order" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Orders</h3>
          <p className="small text-gray-600">Download orders</p>
        </li>
      </Link>

      <Link to="/tools/products/templates">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="template" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Product Template Associations</h3>
          <p className="small">Bulk-edit your product template associations</p>
        </li>
      </Link>

      <Link to="/tools/categories/templates">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="template" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Category Template Associations</h3>
          <p className="small">Bulk-edit your category template associations</p>
        </li>
      </Link>

      <Link to="/tools/brands/templates">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="template" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Brand Template Associations</h3>
          <p className="small">Bulk-edit your brand template associations</p>
        </li>
      </Link>

      <Link to="/tools/redirects">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="redirect" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Redirects</h3>
          <p className="small">Bulk-edit your 301 redirects</p>
        </li>
      </Link>

      <Link to="/tools/coupons/download">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="coupon" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Download Coupons </h3>
          <p className="small text-gray-600">Download your coupons data</p>
        </li>
      </Link>

      <Link to="/tools/giftcertificates">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="gift" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Gift Certificates </h3>
          <p className="small text-gray-600">
            Download and bulk-edit gift certificates
          </p>
        </li>
      </Link>

      <Link to="/tools/product-reviews">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="review" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Product Reviews</h3>
          <p className="small text-gray-600">
            Download & bulk-edit your product review data
          </p>
        </li>
      </Link>

      <Link to="/tools/pages">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="pages" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Pages</h3>
          <p className="small text-gray-600">
            Bulk-edit your custom pages properties
          </p>
        </li>
      </Link>

      <Link to="/tools/customer-addresses">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="addresses" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Customer Addresses</h3>
          <p className="small">Bulk-edit your customer addresses</p>
        </li>
      </Link>

      <Link to="/tools/product-admin-one-click">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <span className="absolute top-3 right-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            FREE
          </span>
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="pointer" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">Admin shortcut</h3>
          <p className="small">
            Go from a public product page to the admin view in one click
          </p>
        </li>
      </Link>

      <a className="" href="mailto:support@bctools.io">
        <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
          <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
            <SVG name="magic" size={62} lineWidth={1.5} />
          </div>
          <h3 className="mt-4 font-semibold">The Tool You Want</h3>
          <p className="small">
            What data tool do you want to make your life easier? Let us know!
          </p>
        </li>
      </a>

      {store.bc_hash === 'g6oxherh18' && (
        <Link to="/tools/custom/GlobalOneTechnology/category-descriptions">
          <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
            <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
              <SVG name="code" size={62} lineWidth={1.5} />
            </div>
            <h3 className="mt-4 font-semibold">Category Descriptions</h3>
            <p className="small">Generate category descriptions</p>
          </li>
        </Link>
      )}

      {store.bc_hash === 'fbaa7' && (
        <Link to="/tools/custom/SinnFein/orders">
          <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
            <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
              <SVG name="order" size={62} lineWidth={1.5} />
            </div>
            <h3 className="mt-4 font-semibold">Orders</h3>
            <p className="small text-gray-600">
              Sinn Féin orders download with HS codes
            </p>
          </li>
        </Link>
      )}

      {user.props.isAdmin && (
        <Link to="/tools/_test">
          <li className="col-span-1 flex flex-col flex-1 items-center justify-center text-center bg-white rounded-lg shadow h-full p-8 relative transition hover:scale-105 group">
            <span className="absolute top-3 right-3 px-2 text-sm text-white font-semibold bg-orange-500 rounded flex items-center">
              DEVELOPER ONLY
            </span>
            <div className="w-42 h-42 flex-shrink-0 mx-auto text-gray-500 group-hover:text-indigo-500">
              <SVG name="options" size={62} lineWidth={1.5} />
            </div>
            <h3 className="mt-4 font-semibold">Testing</h3>
            <p className="small">Test download & import APIs</p>
          </li>
        </Link>
      )}
    </ul>
  )
}

export default Tools
