import useEntityFilter from './useEntityFilter'

const useCategoriesFilter = () => {
  const { filterPanel, selectedEntities } = useEntityFilter('categories')

  return {
    categoriesPanel: filterPanel,
    selectedCategories: selectedEntities,
  }
}

export default useCategoriesFilter
