import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import {
  createFileName,
  downloadBlob,
  fromLocalStorage,
  createFieldArray,
} from '../../../common'
import Plausible from 'plausible-tracker'
import useCategoriesFilter from '../../export/useCategoriesFilter'
import useBrandsFilter from '../../export/useBrandsFilter'

const { trackEvent } = Plausible()

const STORAGE_KEY = 'productsCustomFieldsOptions'

const productProps = {
  name: true,
  sku: true,
  upc: false,
  mpn: false,
  gtin: false,
  categories: false,
  brand_id: false,
}

const ProductsCustomFieldsDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { categoriesPanel, selectedCategories } = useCategoriesFilter()
  const { brandsPanel, selectedBrands } = useBrandsFilter()

  /* "Remember" the user's seclections */
  const getOptionDefaultsFromLocalStorage = () => {
    const savedOptions = fromLocalStorage(STORAGE_KEY) || {}

    // Eventually this can be deleted.
    /*
    if (savedOptions.productProps) {
      savedOptions.productProps = savedOptions.productProps.filter(
        (p) => !['brand_id', 'categories'].includes(p)
      )
    }
    */

    return {
      productProps: savedOptions.productProps || createFieldArray(productProps),
    }
  }

  const [formData, setFormData] = useState(getOptionDefaultsFromLocalStorage())
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const handleArrayItemToggle = (arrayName, key) => {
    const field = formData[arrayName]
    const newField = field.includes(key)
      ? field.filter((item) => item !== key)
      : [...field, key]
    setFormData((formData) => ({
      ...formData,
      [arrayName]: newField,
    }))
    setError(null)
  }

  const handleProductColumnToggle = (event) => {
    handleArrayItemToggle('productProps', event?.target?.id)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Custom Fields download')
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/products/custom-fields/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({
            ...formData,
            categories: selectedCategories.map((e) => e.id),
            brands: selectedBrands.map((e) => e.id),
          }),
        }
      )
      if (res.status !== 200) {
        throw new Error(res.status)
      }
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName(
            'products-custom-fields',
            storesCtx.store.bc_hash,
            'csv'
          )
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="swatch" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">Custom Fields</div>
        </h5>
      </div>

      <form
        method="post"
        action="#"
        onSubmit={handleSubmit}
        className="flex flex-col"
      >
        <div className="flex mt-4">
          <div className="flex-1">
            <div className="pt-4">
              <div className="font-bold text-lg">Download fields</div>
              <div className="text-sm text-gray-500">
                Select fields to include in download...
              </div>
            </div>
            <div className="mt-2 items-center justify-between">
              <Checkbox key="id" id="id" value={true} disabled={true} />
              {Object.keys(productProps).map((id) => (
                <Checkbox
                  key={id}
                  id={id}
                  value={formData.productProps.includes(id)}
                  onChange={handleProductColumnToggle}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            {categoriesPanel}
            {brandsPanel}
          </div>
        </div>
        {error && <Error msg={error} />}
        <div className="mt-2">
          <span className="block w-full py-4">
            <button
              onClick={runTool}
              disabled={disabled}
              type="button"
              className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                disabled ? 'bg-gray-500' : 'bg-blue-600'
              } ${
                !disabled && 'hover:bg-blue-500'
              } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
            >
              {waiting ? (
                <SVG name="spinner" size={32} />
              ) : (
                <SVG name="download" size={32} lineWidth={1} />
              )}
              <div className="ml-2">Download</div>
            </button>
          </span>
        </div>

        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                It may take several seconds for your CSV file to be generated
                and start downloading.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductsCustomFieldsDownload
