import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import VariantsDownload from './VariantsDownload'
import VariantsImport from './VariantsImport'

export default function VariantsManagePage() {
  return (
    <ToolPageLayout2col>
      <VariantsDownload />
      <VariantsImport />
    </ToolPageLayout2col>
  )
}
