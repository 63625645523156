import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const FORCE_DISABLED = false

const resultMap = {
  entities: false,
  updated: 'options updated',
  created: false,
  deleted: false,
  validated: 'rows validated',
}

const ProductOptionsImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Product options import',
      method: 'product-options/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled || FORCE_DISABLED} launched={true} />
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}

      <div className="rounded-md opacity-30 mt-auto pt-4">
        <InfoNote title="Have a backup?">
          Make sure you have a{' '}
          <span className="font-bold">recent, unmodified CSV file</span> in case
          you need to restore your data.{' '}
        </InfoNote>
        <InfoNote title="V3 ONLY">
          Do not import if your store is using v2 options.
        </InfoNote>
        <InfoNote title="UPPER-CASE">
          Do not edit the columns with all UPPER-CASE headers.
        </InfoNote>
      </div>
    </>
  )
}

export default ProductOptionsImport
