import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const FORCE_DISABLED = false

const resultMap = {
  entities: false,
  updated: false,
  created: false,
  deleted: false,
  validated: false,
}

const ProductsImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Products import',
      method: 'products/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled || FORCE_DISABLED} launched={true} />
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}

      <div className="rounded-md opacity-30 mt-auto pt-4">
        <InfoNote title="Have a backup?">
          Make sure you have a{' '}
          <span className="font-bold">recent, unmodified CSV file</span> in case
          you need to restore your data.{' '}
        </InfoNote>
        <InfoNote title="Update only">
          Import does not yet support creating new products.
        </InfoNote>
        <InfoNote title="Won't delete">
          Import process <em>will not</em>&nbsp;delete any products.{' '}
        </InfoNote>
        <InfoNote title='Hey! Where&apos;s the "Validation" phase?'>
          We recently changed this import tool. We removed the validation phase in order to speed up
          product importing by more than 2x.
        </InfoNote>
      </div>
    </>
  )
}

export default ProductsImport
