import { useContext } from 'react'
import { UserContext } from '../Context/User'
import useFileDragDrop from './useFileDragDrop'
import useFileImport from './useFileImport'

const useImport = ({method, resultMap}) => {

  const user = useContext(UserContext)
  const { file, reset, FileSelector } = useFileDragDrop({ method })
  const { disabled, FileImport } = useFileImport({
    method,
    file,
    resultMap,
    onReset: reset,
  })

  return {
    FileSelector,
    FileImport,
    result: user[method],
    disabled,
    fileSelected: !!file,
  }

}

export default useImport
