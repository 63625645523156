import React from 'react'
import SVG from '../SVG'

const Complete = ({ reset }) => (
  <button onClick={reset} className="bg-green-500 px-4 py-2 rounded-md">
    <div className="flex text-left items-center">
      <span className="text-green-200">
        <SVG name="checkmark" size={20} lineWidth={3} />
      </span>
      <div className="ml-3 flex-1">
        <p className="text-sm text-white">Import complete</p>
      </div>
      <div className="text-white hover:text-gray-300">
        <SVG name="close" size={24} lineWidth={1.5} />
      </div>
    </div>
  </button>
)

export default Complete
