import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductsCustomsInfoDownload from './ProductsCustomsInfoDownload'
import ProductsCustomsInfoImport from './ProductsCustomsInfoImport'

export default function BrandsManagePage() {
  return (
    <>
      <ToolPageLayout2col>
        <ProductsCustomsInfoDownload />
        <ProductsCustomsInfoImport />
      </ToolPageLayout2col>
    </>
  )
}
