import React, { useContext } from 'react'
import { StoresContext } from './Context/Stores'
import Store from './Store'

const StoresList = () => {
  const storesCtx = useContext(StoresContext)

  const { store, stores } = storesCtx

  // Ensure selected store at top.
  const sortedStores = [
    stores.find((s) => s.bc_hash === store.bc_hash ),
    ...stores.filter((s) => s.bc_hash !== store.bc_hash),
  ].filter((s) => s)

  return (
    <div className="-my-2">
      {sortedStores.map((s) => (
        <Store
          key={s.bc_hash}
          store={s}
        />
      ))}
    </div>
  )
}

export default StoresList
