import React, { useState, useEffect } from 'react'
import useGetEntities from './useGetEntities'
import SelectedEntities from './SelectedEntities'
import { XIcon } from '@heroicons/react/outline'

const SelectEntities = ({ entityType, selectedItems, onSelection }) => {
  const { entities } = useGetEntities(entityType)

  const [filter, setFilter] = useState('')
  const [matchingEntities, setMatchingEntities] = useState([])
  const [selectedEntities, setSelectedEntities] = useState(selectedItems)

  const sortedMatchingEntities = matchingEntities.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  const handleFilterChange = ({ target: { value } }) => {
    setFilter(value.toLowerCase())
  }

  const clearFilter = () => {
    setFilter('')
    setMatchingEntities([])
  }

  const filterEntities = () => {
    const regex = new RegExp(filter)
    setMatchingEntities(
      entities.filter(
        (entity) =>
          regex.test(entity.name.toLowerCase()) || `${entity.id}` === filter
      )
      // Too jarring to remove categories from matching list if already selected
      // entities.filter((entity) => !selectedEntities.find((e) => e.name === entity.name) && regex.test(entity.name.toLowerCase()))
    )
  }

  useEffect(() => {
    if (
      filter.length > 2 ||
      (filter.length > 0 && filter.replace(/\D+/g, '') === filter)
    ) {
      filterEntities()
    } else {
      setMatchingEntities([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const addEntity = (entity) => {
    setSelectedEntities((state) => [
      ...state.filter((e) => e.name !== entity.name),
      entity,
    ])
  }

  const removeEntitiy = (name) => {
    setSelectedEntities((state) => [...state.filter((c) => c.name !== name)])
  }

  useEffect(() => {
    onSelection(selectedEntities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntities])

  return (
    <div className="sm:flex w-full mt-5">
      <SelectedEntities
        entityType="categories"
        entities={selectedEntities}
        onRemove={removeEntitiy}
      />

      <div className="p-4 sm:w-1/2 bg-gray-50 rounded-lg">
        <div className="flex items-center border border-gray-400 rounded-md bg-white focus-within:outline focus-within:outline-offset-1 focus-within:outline-2 focus-within:outline-blue-300">
          <input
            type="text"
            className="p-0 px-2 bg-transparent focus:border-0 focus:outline-none flex-1"
            onChange={handleFilterChange}
            value={filter}
            placeholder="search..."
          />
          <button
            type="button"
            className="my-3 mx-2 text-gray-400 hover:text-red-500 ml-auto"
            onClick={clearFilter}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        <div className="mt-4 h-96 overflow-auto flex content-start flex-wrap gap-2">
          {sortedMatchingEntities.map((c) => (
            <div key={c.id}>
              <button
                type="button"
                className="text-left text-sm px-2 py-1 border border-gray-300 rounded-md text-gray-600 hover:bg-blue-100 hover:border-blue-300"
                onClick={() => addEntity(c)}
              >
                {c.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SelectEntities
