import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductReviewsDownloadAsync from './ProductReviewsDownloadAsync'
import ProductReviewsImport from './ProductReviewsImport'


export default function CategoriesManagePage() {
  return (
    <ToolPageLayout2col>
      <ProductReviewsDownloadAsync />
      <ProductReviewsImport />
    </ToolPageLayout2col>
  )
}
