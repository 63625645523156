import React, { useReducer, createContext, useContext } from 'react'
import { UserContext } from './User'
import { API_URL, fetchCfg } from '../../config'
import { fromLocalStorage } from '../../common'

const initialState = {
  store: {},
  stores: [],
  saving: false,
  loading: true,
}

const sortByTimestampDesc = (key) => (a, b) => {
  if (a[key] < b[key]) {
    return 1
  }
  if (a[key] > b[key]) {
    return -1
  }
  return 0
}

const reducer = (state, action) => {
  const { type, ...data } = action
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        ...data,
      }
    case 'SET_STORE_SUMMARY':
      return {
        ...state,
        store: { ...state.store, ...data },
      }
    case 'SET_STORE_COUNTS':
      const updatedStore =
        state.store.bc_hash === action.data.bc_hash
          ? {
              ...state.store,
              ...action.data,
            }
          : state.store

      const storesList = [
        ...state.stores.filter((s) => s.bc_hash !== updatedStore.bc_hash),
        updatedStore,
      ].sort(sortByTimestampDesc('last_select_ts'))
      return {
        ...state,
        store: updatedStore,
        stores: storesList,
      }

    default:
      return state
  }
}

const Context = createContext()

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const user = useContext(UserContext)

  state.reset = async () => {
    dispatch({
      type: 'SET_STATE',
      ...initialState,
    })
  }

  state.getSummary = async (storeHash) => {
    const bc_hash = storeHash || state.store?.bc_hash
    try {
      const res = await fetch(`${API_URL}/${bc_hash}/store/summary`, {
        ...fetchCfg(user.csrfToken),
      })
      if (res.status > 403) {
        return console.error(res)
      }
      const data = await res.json()
      if (data.error) {
        return console.log(data.error)
      }
      // Success
      dispatch({
        type: 'SET_STORE_SUMMARY',
        ...data,
      })
    } catch (error) {
      console.error(error)
    }
  }

  state.getStores = async () => {
    try {
      dispatch({
        type: 'SET_STATE',
        loading: true,
      })
      const res = await fetch(`${API_URL}/stores`, {
        ...fetchCfg(user.csrfToken),
      })
      if (res.status > 403) {
        return console.error(res)
      }
      const stores = await res.json()
      if (stores.error) {
        return console.log(stores.error)
      }
      // Success
      dispatch({
        type: 'SET_STATE',
        stores,
      })
      if (stores.length) {
        const selectedStoreHash = fromLocalStorage('store')
        const store =
          stores.find((s) => s.bc_hash === selectedStoreHash) || stores[0]
        localStorage.setItem('store', JSON.stringify(store.bc_hash))
        dispatch({
          type: 'SET_STATE',
          store,
        })
        state.getSummary(selectedStoreHash)
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch({
        type: 'SET_STATE',
        loading: false,
      })
    }
  }

  state.selectStore = (bc_hash) => {
    console.log({ bc_hash, stores: state.stores })
    const store = state.stores.find((s) => s.bc_hash === bc_hash)
    if (!store) {
      return console.error(`Invalid store selected: ${bc_hash}`)
    }
    localStorage.setItem('store', JSON.stringify(store.bc_hash))
    dispatch({
      type: 'SET_STATE',
      store,
    })
    state.getSummary(store.bc_hash)
  }

  state.saveStore = async (formData) => {
    dispatch({ type: 'SET_STATE', saving: true })
    try {
      const res = await fetch(`${API_URL}/store`, {
        ...fetchCfg(user.csrfToken),
        method: 'post',
        body: JSON.stringify(formData),
      })
      if (res.status > 403) {
        console.error(res)
        return { error: 'something went wrong' }
      }
      const json = await res.json()
      if (json.error) {
        console.error(json.error)
        return { error: json.error }
      }
      // Success
      localStorage.setItem('store', JSON.stringify(formData.bc_hash))
      await state.getStores()
      return { success: true }
    } catch (error) {
      console.error(error)
      return { error: 'something went wrong' }
    } finally {
      dispatch({ type: 'SET_STATE', saving: false })
    }
  }

  state.deleteStore = async (bc_hash) => {
    try {
      const res = await fetch(`${API_URL}/${bc_hash}/store`, {
        ...fetchCfg(user.csrfToken),
        method: 'delete',
      })
      if (res.status > 403) {
        return console.error(res)
      }
      const json = await res.json()
      if (json.error) {
        return console.error(json.error)
      }
      // Success
      state.getStores()
    } catch (error) {
      return console.error(error)
    }
  }

  return (
    <Context.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Provider as StoresProvider, Context as StoresContext }
