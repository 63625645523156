import React, { useContext, useState } from 'react'
import Plausible from 'plausible-tracker'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import { createFileName, downloadBlob } from '../../../common'
import EntitySelect from '../../export/EntitySelect'

const { trackEvent } = Plausible()

const CustomerGroupsCategoryDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { store } = storesCtx

  const [mode, setMode] = useState('')
  const [category, setCategory] = React.useState('')
  const [group, setGroup] = React.useState('')

  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const runTool = async (catOrGroup) => {
    setWaiting(true)
    trackEvent('Customer Groups Category download')
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(
        `${API_URL}/${store.bc_hash}/customer-groups/${mode}/${
          mode === 'group' ? group?.id : category?.id
        }/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({}),
        }
      )
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName(
            `customer-groups-${mode === 'group' ? 'group' : 'category'}`,
            store.bc_hash,
            'csv'
          )
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled =
    !user.authenticated ||
    !store.bc_hash ||
    (mode === 'group' && !group) ||
    (mode === 'category' && !category)

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="customerGroup" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">
            Customer Group Category Assignments
          </div>
        </h5>
      </div>

      {!mode && (
        <div>
          <button
            onClick={() => setMode('category')}
            type="button"
            className="m-4 flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
          >
            <SVG name="user" size={32} />
            <div className="ml-2">Manage Customer Groups for a Category</div>
          </button>
          <button
            onClick={() => setMode('group')}
            type="button"
            className="m-4 flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
          >
            <SVG name="categories" size={32} />
            <div className="ml-2">Manage Categories for a Customer Group</div>
          </button>
        </div>
      )}

      {mode === 'category' && (
        <>
          <div className="pt-4">
            <div className="font-bold text-lg">
              Download customer groups for a category
            </div>
            <div>
              <p className="text-sm leading-6 text-gray-700">
                Select a single category, then dowload a list of all your
                customer groups to manage which should have the category
                assigned.
              </p>
              <p>
                <button
                  type="button"
                  onClick={() => setMode('group')}
                  className="link"
                >
                  select a customer group instead
                </button>
              </p>
            </div>
            <EntitySelect
              entityType="categories"
              selectedEntity={category}
              setSelectedEntity={setCategory}
            />
          </div>
        </>
      )}

      {mode === 'group' && (
        <>
          <div className="pt-4">
            <div className="font-bold text-lg">
              Download categories for a customer group.
            </div>
            <div>
              <p className="text-sm leading-6 text-gray-700">
                Select a single customer group, then dowload a list of all your
                categories to manage which should be assigned to the customer
                group.
              </p>
              <p>
                <button
                  type="button"
                  onClick={() => setMode('category')}
                  className="link"
                >
                  select a category instead
                </button>
              </p>
            </div>
            <EntitySelect
              entityType="customer-groups"
              selectedEntity={group}
              setSelectedEntity={setGroup}
            />
          </div>
        </>
      )}

      {mode && (
        <>
          {error && <Error msg={error} />}

          <div className="mt-2">
            <div className="w-full py-4">
              <button
                onClick={runTool}
                disabled={disabled}
                type="button"
                className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                  disabled ? 'bg-gray-500' : 'bg-blue-600'
                } ${
                  !disabled && 'hover:bg-blue-500'
                } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
              >
                {waiting ? (
                  <SVG name="spinner" size={32} />
                ) : (
                  <SVG name="download" size={32} lineWidth={1} />
                )}
                <div className="ml-2">Download</div>
              </button>
            </div>
          </div>

          <div className="px-1 py-2 rounded-md opacity-70">
            <div className="flex items-center">
              <span className="text-gray-500">
                <SVG name="info" size={16} />
              </span>
              <div className="ml-3">
                <p className="text-xs text-gray-500">
                  It may take several seconds for your CSV file to be generated
                  and start downloading.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CustomerGroupsCategoryDownload
