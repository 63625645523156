import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductOptionsDownload from './ProductOptionsDownload'
import ProductOptionsImport from './ProductOptionsImport'

export default function BulkPricingManagePage() {
  return (
    <ToolPageLayout2col>
      <ProductOptionsDownload />
      <ProductOptionsImport />
    </ToolPageLayout2col>
  )
}
