import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import TestDownload from './TestDownload'
import TestImport from './TestImport'

export default function TestManagePage() {
  return (
    <>
      <ToolPageLayout2col>
        <TestDownload />
        <TestImport />
      </ToolPageLayout2col>
    </>
  )
}
