import React from 'react'

const ToolPageLayout = ({ children }) => {
  return (
    <main className="bg-gray-50">
      <div className="bg-indigo-600">
        <div className="max-w-7xl mx-auto h-40 px-4 sm:px-6 lg:px-8 text-indigo-300 text-5xl font-semibold border-t border-indigo-500"></div>
      </div>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 -mt-40">
        <div className="md:flex items-stretch md:space-x-6 lg:space-x-8">
          <div className="bg-white rounded-lg p-6 shadow w-full">
            {children}
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="px-6 py-4 sm:px-0"></div>
        </div>
      </div>
    </main>
  )
}

export default ToolPageLayout
