import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from './Context/User'
import { StoresContext } from './Context/Stores'
import Tools from './Tools'
import StoresList from './StoresList'
import SVG from './SVG'
import Loading from './Loading'

const Member = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { stores } = storesCtx

  if (user.isAuthenticating || (user.authenticated && storesCtx.loading)) { return <Loading /> }

  let mode = 'visitor'
  if (user.authenticated) {
    if (user.props.expired) {
      mode = 'expired'
    } else {
      if (stores.length > 0) {
        mode = 'user'
      } else {
        mode = 'welcome'
      }
    }
  }

  return (
    <>
      {mode === 'welcome' ? (
        <main className="bg-indigo-600 flex-1 flex">
          <div className="max-w-7xl mx-auto pb-6 pt-20 px-4 sm:px-6 lg:px-8 text-indigo-300 border-t border-indigo-500 flex items-center justify-center flex-1">
            <div className="p-4 md:p-8 md:w-1/2 lg:w-1/3 rounded-lg bg-white text-left shadow sm:rounded-lg">
              <svg
                width="294"
                height="307"
                xmlns="http://www.w3.org/2000/svg"
                className="block mx-auto -mt-40 mb-8"
              >
                <g
                  transform="translate(.794 .25)"
                  fillRule="nonzero"
                  fill="none"
                >
                  <ellipse
                    fill="#3F3D56"
                    opacity=".182"
                    cx="113.123"
                    cy="286.016"
                    rx="113.123"
                    ry="19.984"
                  />
                  <circle
                    fill="#3F3D56"
                    opacity=".091"
                    cx="181.877"
                    cy="110.556"
                    r="110.556"
                  />
                  <circle
                    fill="#000"
                    opacity=".091"
                    cx="181.877"
                    cy="110.556"
                    r="91.268"
                  />
                  <circle
                    fill="#000"
                    opacity=".091"
                    cx="181.877"
                    cy="110.556"
                    r="74.802"
                  />
                  <circle
                    fill="#000"
                    opacity=".091"
                    cx="181.877"
                    cy="110.556"
                    r="53.631"
                  />
                  <path
                    d="M72.3 120.45s-8.692 24.466-4.83 33.158c3.864 8.692 9.98 17.062 9.98 17.062s-2.253-48.61-5.15-50.22Z"
                    fill="#D0CDE1"
                  />
                  <path
                    d="M72.3 120.45s-8.692 24.466-4.83 33.158c3.864 8.692 9.98 17.062 9.98 17.062s-2.253-48.61-5.15-50.22Z"
                    fill="#000"
                    opacity=".1"
                  />
                  <path
                    d="M78.416 177.108s-.644 6.117-.966 6.439c-.321.322.322.966 0 1.931-.321.966-.643 2.254 0 2.576.644.322-3.54 28.651-3.54 28.651s-10.302 13.52-6.117 34.768l1.287 21.569s9.98.643 9.98-2.898c0 0-.644-4.185-.644-6.116 0-1.932 1.61-1.932.644-2.898-.966-.965-.966-1.61-.966-1.61s1.61-1.287 1.288-1.609c-.322-.322 2.897-23.178 2.897-23.178s3.541-3.541 3.541-5.473v-1.932s1.61-4.185 1.61-4.506c0-.322 8.692-19.96 8.692-19.96l3.541 14.165 3.863 20.281s1.932 18.35 5.795 25.432c0 0 6.76 23.178 6.76 22.535 0-.644 11.268-2.254 10.946-5.151-.322-2.897-6.76-43.46-6.76-43.46l1.609-60.2-43.46.644Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M69.724 271.432s-8.692 17.062-2.897 17.706c5.795.643 8.048.643 10.623-1.932 1.408-1.408 4.26-3.297 6.555-4.72a8.477 8.477 0 0 0 4.022-8.06c-.17-1.576-.758-2.873-2.207-2.994-3.863-.322-8.37-3.863-8.37-3.863l-7.726 3.863ZM117.69 283.987s-8.691 17.062-2.896 17.706c5.794.643 8.048.643 10.623-1.932 1.408-1.408 4.26-3.297 6.555-4.72a8.477 8.477 0 0 0 4.022-8.06c-.17-1.576-.758-2.873-2.207-2.994-3.863-.322-8.37-3.863-8.37-3.863l-7.726 3.863Z"
                    fill="#2F2E41"
                  />
                  <circle fill="#FFB8B8" cx="108.504" cy="78.996" r="13.532" />
                  <path
                    d="M99.805 83.443s-9.666 17.785-10.44 17.785c-.773 0 17.4 5.8 17.4 5.8s5.026-17.013 5.8-18.56l-12.76-5.025Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M114.633 102.905S95.317 92.282 93.386 92.604c-1.932.322-22.535 18.35-22.213 25.754.322 7.404 2.897 19.637 2.897 19.637s.966 34.124 2.898 34.446c1.931.322-.322 6.116.322 6.116.643 0 45.069 0 45.39-.966.323-.965-8.047-74.686-8.047-74.686Z"
                    fill="#D0CDE1"
                  />
                  <path
                    d="M125.417 179.362s6.117 18.671.966 18.028c-5.151-.644-7.404-16.097-7.404-16.097l6.438-1.931Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M108.999 101.779s-11.911 2.575-9.98 18.671c1.932 16.096 5.473 32.192 5.473 32.192l11.911 26.076 1.288 4.829 8.692-2.254-6.439-37.343s-2.253-39.918-5.15-41.206a12.467 12.467 0 0 0-5.795-.965Z"
                    fill="#D0CDE1"
                  />
                  <path
                    fill="#000"
                    opacity=".1"
                    d="m101.756 152.16 14.808 26.397-12.476-27.816z"
                  />
                  <path
                    d="m121.977 75.012.045-1.035 2.057.512a2.299 2.299 0 0 0-.922-1.692l2.191-.122a23.643 23.643 0 0 0-15.813-9.773c-4.74-.687-10.017.307-13.267 3.825-1.577 1.706-2.567 3.876-3.272 6.09-1.298 4.076-1.562 8.936 1.144 12.25 2.75 3.368 7.554 4.028 11.883 4.445 1.523.146 3.119.283 4.53-.308a10.898 10.898 0 0 0-.607-4.78 3.185 3.185 0 0 1-.322-1.523c.193-1.288 1.91-1.612 3.2-1.438 1.29.174 2.842.44 3.69-.548.584-.68.55-1.672.627-2.566.21-2.432 4.814-2.827 4.836-3.337Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M187.032 246.283a.692.692 0 0 1 .907-.059l13.392 7.633a12.577 12.577 0 0 0-1.764 2.814 12.827 12.827 0 0 0-1.041 3.224 5.09 5.09 0 0 0 .113 2.158l3.589 13.392a1.053 1.053 0 0 0 1.29.746l13.48-3.612a5.21 5.21 0 0 0 1.916-.973 12.37 12.37 0 0 0 3.837-5.333l13.326 7.744c.32.132.491.48.403.814a.856.856 0 0 1-.636.725l-37.88 10.15a.893.893 0 0 1-1.091-.63l-10.15-37.88a.779.779 0 0 1 .31-.913Zm31.791-6.11c.288-.496.92-.672 1.422-.395l5.253 2.894a1.055 1.055 0 0 1 .403 1.45l-5.684 9.844a4.89 4.89 0 0 0-.364 4.075 8.867 8.867 0 0 1-6.064 11.51 5.764 5.764 0 0 1-.858.188 1.146 1.146 0 0 1-1.135-.488 1.172 1.172 0 0 1-.05-1.263l1.985-3.439a2.226 2.226 0 0 0-.813-3.035l-2.625-1.523a2.248 2.248 0 0 0-1.663-.2 2.284 2.284 0 0 0-1.358 1.023l-1.989 3.447a1.164 1.164 0 0 1-.706.554l-.014.004a1.206 1.206 0 0 1-1.333-.54 8.857 8.857 0 0 1 5.94-11.838c.22-.064.443-.114.668-.152a4.9 4.9 0 0 0 3.363-2.373Z"
                    fill="#6C63FF"
                  />
                  <circle fill="#6C63FF" cx="19.801" cy="267.498" r="15.768" />
                  <circle fill="#6C63FF" cx="19.801" cy="246.597" r="11.367" />
                  <circle fill="#6C63FF" cx="19.801" cy="228.996" r="8.067" />
                </g>
              </svg>
              <h2 className="text-gray-800 text-3xl pb-0">
                Connect to your store
              </h2>
              <p className="pb-12 text-gray-500">
                Before we can help you manage your store data, we need to
                connect to it. Let’s configure your first store connection now.
              </p>
              <div className="rounded-md shadow">
                <Link
                  to="/stores"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-2xl md:px-10"
                >
                  Add first store
                </Link>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <main className="bg-gray-100 flex-1">
          <div className="bg-indigo-600">
            <div className="max-w-7xl mx-auto pb-6 pt-20 px-4 sm:px-6 lg:px-8 text-indigo-300 text-5xl font-semibold border-t border-indigo-500">
              The Toolbox
            </div>
          </div>
          <div className="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8 pb-0 h-full flex-1">
            <div className="flex flex-col md:flex-row">
              <div className="md:w-3/4 order-1 md:pr-6">
                <Tools />
              </div>

              {/* Right column: 4 diferent views controlled by `mode` */}
              {mode === 'user' && (
                <div className="p-4 md:w-1/4 rounded-lg bg-gray-200 md:order-1 mb-6 md:mb-0">
                  <StoresList />
                  <Link
                    to="/stores"
                    className="block rounded-lg text-gray-600 hover:bg-gray-300 text-center p-2 mt-2 transition"
                  >
                    + add store
                  </Link>
                </div>
              )}

              {mode === 'expired' && (
                <div className="p-4 md:w-1/4 rounded-lg bg-gray-200 border-2 border-red-500 md:order-1 mb-6 md:mb-0 flex flex-col items-center">
                  <SVG name="error" size={62} className="mt-10" />
                  <p className="py-4">Your access has expired.</p>
                  <div className="rounded-md shadow">
                    <Link
                      to="/pricing"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Reactivate now
                    </Link>
                  </div>
                </div>
              )}

              {mode === 'visitor' && (
                <div className="p-4 md:w-1/4 rounded-lg bg-gray-200 md:order-1 mb-6 md:mb-0">
                  <p className="py-4">
                    Using these tools, you can bulk-edit brands, categories, custom fields, and more.
                  </p>
                  <div className="rounded-md shadow">
                    <Link
                      to="/pricing"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      )}
    </>
  )
}

export default Member
