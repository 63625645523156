import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const FORCE_DISABLED = false

const resultMap = {
  entities: false,
  updated: 'product images updated',
  created: 'product images created',
  deleted: 'product images deleted',
  validated: false,
}

const ProductImagesImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Product images import',
      method: 'product-images/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled || FORCE_DISABLED} launched={true} />
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}

      <div className="rounded-md opacity-30 mt-auto pt-4">
        <InfoNote title="Have a backup?">
          Make sure you have a{' '}
          <span className="font-bold">recent, unmodified CSV file</span> in case
          you need to restore your data.{' '}
        </InfoNote>
        <InfoNote title="DELETE">
          To delete an image, enter "DELETE" in the "image_url" column.
        </InfoNote>
        <InfoNote title="img_url max 255 characters">
          When updating or creating an image with img_url, the max length is 255 characters.
        </InfoNote>
      </div>
    </>
  )
}

export default ProductImagesImport
