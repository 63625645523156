import React, { useContext, useState } from 'react'
import Plausible from 'plausible-tracker'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'

const { trackEvent } = Plausible()

const ProductsReviewsDownloadAsync = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { store } = storesCtx

  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState()

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Product Reviews download')
    await createFile()
    setWaiting(false)
  }

  const createFile = async () => {
    setError(null)
    try {
      const res = await fetch(
        `${API_URL}/${store.bc_hash}/products/reviews/export/async`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
        }
      )
      if (res.status === 204) {
        setError('No product reviews found')
        return
      }
      const json = await res.json()
      console.log(json)
      setMessage(json.message)
    } catch (err) {
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="review" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">Product Reviews</div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="pt-4">
          <div className="font-bold text-lg">Download reviews</div>
        </div>

        {error && <Error msg={error} />}

        {message ? (
          <div className="my-6 px-6 pt-4 pb-6 text-sm bg-green-50 rounded-md max-w-[500px]">
            <h2 className="text-green-900">
              Generating download file
              <span className="text-green-800 animate-pulse">...</span>
            </h2>
            <p>
              You will be notified by email when your file is ready for
              download.
            </p>
            <p>
              Depending on how many products and reviews you have, this process
              can take a relatively long time. Go catch up on email or take a
              friend out to lunch. ☕️
            </p>
          </div>
        ) : (
          <div className="mt-2">
            <span className="block w-full py-4">
              <button
                onClick={runTool}
                disabled={disabled}
                type="button"
                className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                  disabled ? 'bg-gray-500' : 'bg-blue-600'
                } ${
                  !disabled && 'hover:bg-blue-500'
                } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
              >
                {waiting ? (
                  <SVG name="spinner" size={32} />
                ) : (
                  <SVG name="download" size={32} lineWidth={1} />
                )}
                <div className="ml-2">Download</div>
              </button>
            </span>
          </div>
        )}

        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                Exporting product reviews can take several minutes. When you click the button,
                the file will be generated and a download link emailed to {user.props?.email || 'you'}
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductsReviewsDownloadAsync
