import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const FORCE_DISABLED = false

const resultMap = {
  entities: false,
  updated: 'rules updated',
  created: 'rules created',
  deleted: 'rules deleted',
  validated: 'rules validated',
  parentsUpdated: 'products updated',
}

const BulkPricingImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Bulk pricing import',
      method: 'bulk-pricing/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled || FORCE_DISABLED} launched={true} />
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}

      <div className="rounded-md opacity-30 mt-auto pt-4">
        <InfoNote title="Have a backup?">
          Make sure you have a{' '}
          <span className="font-bold">recent, unmodified CSV file</span> in case
          you need to restore your data.{' '}
        </InfoNote>
        <InfoNote title="Why is the deleted count not what I expect?">
          The import will update rules when it can and create new rules as
          necessary. Sometimes due to overlapping rules, the import must 1st
          delete the existing rules then create new rules to match your import
          file.
        </InfoNote>
      </div>
    </>
  )
}

export default BulkPricingImport
