import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { StoresContext } from './Context/Stores'
import SVG from './SVG'
import YesOrNo from './YesOrNo'

const Store = ({ store, history }) => {
  const storesCtx = useContext(StoresContext)
  const [showYesOrNo, setShowYesOrNo] = useState(false)

  const selected = store.bc_hash === storesCtx.store.bc_hash

  const confirmDelete = () => {
    setShowYesOrNo(true)
  }

  const deleteThis = () => {
    setShowYesOrNo(false)
    storesCtx.deleteStore(store.bc_hash)
  }

  return (
    <>
      <button
        onClick={() => {
          storesCtx.selectStore(store.bc_hash)
          history.push('/tools')
        }}
        type="button"
        className={`my-2  p-4 w-full rounded-lg relative border-2 text-left transition  ${
          selected
            ? 'border-indigo-600 hover:border-indigo-800 bg-white'
            : 'border-gray-300 bg-gray-100 hover:border-indigo-600 bg-white/50 hover:bg-white'
        }`}
      >
        <div className="text-xl">{store.bc_name}</div>

        {store?.summary && (
          <div className="hidden">
            brands ({store.summary.brands_count?.toLocaleString()}) categories (
            {store.summary.categories_count?.toLocaleString()}) skus: (
            {store.summary.variant_count?.toLocaleString()})
          </div>
        )}

        <div className="flex mt-4 items-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="inline truncate text-xs text-gray-500 hover:text-blue-500"
            href={store.bc_url}
          >
            {store.bc_url}
          </a>
          <div
            onClick={confirmDelete}
            className="cursor-pointer ml-auto flex items-center"
          >
            <SVG
              name="trash"
              className="h-4 w-4 text-gray-300 hover:text-red-500"
            />
          </div>
        </div>
      </button>
      {showYesOrNo && (
        <YesOrNo
          title="Revoke access to store"
          message={`Are you sure you want to revoke your access to ${store.bc_name}`}
          doYes={deleteThis}
          showMe={setShowYesOrNo}
        />
      )}
    </>
  )
}

export default withRouter(Store)
