/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import SVG from '../SVG'

const ProductAdminOneClick = () => {
  return (
    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="p-6 sm:p-4">
            <h5 className="text-lg leading-6 font-medium text-gray-900">
              Product Admin One-Click
            </h5>
            <p className="mt-4 text-base leading-6 text-gray-700">
              Have you ever been on a product page in your storefront and needed
              to open the admin tool for that product? Of course the answer is
              YES!
            </p>

            <p className="my-5">
              Simply <span className="font-bold">drag & drop</span> this button
              into your Bookmarks Bar!
            </p>
            <p className="my-4">
              <a
                className="py-1 px-2 text-gray-100 bg-blue-500 rounded-md"
                href="javascript:(function()%7Bwindow.open('%2Fmanage%2Fproducts%2Fedit%2F'%20%2B%20document.getElementsByName('product_id')%5B0%5D.value)%7D)()"
              >
                bcProdAdmin
              </a>
            </p>

            <p className="my-5">
              If the above bookmarklet does not open the correct URL
              (/products/edit/123), you can try this one (/products/123/edit):
            </p>
            <p className="my-4">
              <a
                className="py-1 px-2 text-gray-100 bg-blue-500 rounded-md"
                href="javascript:(function()%7Bwindow.open('%2Fmanage%2Fproducts%2F'%20%2B%20document.getElementsByName('product_id')%5B0%5D.value%20%2B%20'%2Fedit')%7D)()"
              >
                bcProdAdmin
              </a>
            </p>

            <p className="my-5">
              Then you will be able to one-click open your Product Admin tool
              from any product detail page in your store!
            </p>
          </div>

          <div className="p-6 sm:p-4 bg-gray-100 rounded-md">
            <p className="text-lg font-semibold leading-6 text-gray-900">
              Want to learn more about how this works...
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700">
              See the{' '}
              <a
                className="link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.bigcommerce.com/s/question/0D54O00006HKKmcSAH/oneclick-from-store-to-product-admin-"
              >
                BigCommerce Community discussion thread
              </a>{' '}
              on this topic for details and more tips.
            </p>

            <div className="my-6">
              <iframe
                title="youtube"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/vv3O-NOrEDQ"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </div>

            <div className="px-6 py-4 sm:px-0">
              Here is a slick bookmarklet creation tool from Peter Coles:
              <div>
                <a
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mrcoles.com/bookmarklet/"
                >
                  https://mrcoles.com/bookmarklet/
                </a>
              </div>
            </div>

            <div className="px-6 py-4 sm:px-0">
              This is the javascript one-liner that makes this bookmarklet work:
              <div className="whitespace-pre-wrap p-4 bg-gray-300">
                window.open('/manage/products/edit/' +
                document.getElementsByName('product_id')[0].value);
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProductAdminOneClick
