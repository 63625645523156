/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { Link } from 'react-router-dom'

export default function Pricing() {
  return (
    <div className="bg-indigo-600">
      <div className="max-w-7xl mx-auto pb-6 pt-20 px-4 sm:px-6 lg:px-8 text-indigo-300 text-5xl font-semibold border-t border-indigo-500">
        <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
            Pricing
          </h2>
          <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            Select a price to meet your needs
          </p>
          <p className="text-xl text-gray-300">
            Choose the ANNUAL SUBSCRIPTION if you need to manage more than 3
            stores or have an ongoing need. Cancel anytime. Or choose the ONE
            MONTH option if you just need temporary access to get the job done.
          </p>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-indigo-600" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-8 lg:space-y-0">
              <div className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3
                      className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                      id="tier-standard"
                    >
                      ANNUAL SUBSCRIPTION
                    </h3>
                  </div>
                  <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                    $99
                    <span className="ml-1 text-2xl font-medium text-gray-500">
                      /year
                    </span>
                  </div>
                  <p className="mt-5 text-lg text-gray-500">
                    Annual price includes up to 3 stores. Each additional store
                    is $25 per year.
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <div className="rounded-md shadow">
                    <Link
                      to="/signup/ANNUAL"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600"
                      aria-describedby="tier-standard"
                    >
                      Subscribe
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3
                      className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                      id="tier-standard"
                    >
                      30 Days
                    </h3>
                  </div>
                  <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                    $25
                  </div>
                  <p className="mt-5 text-lg text-gray-500">
                    30 days of access for up to 3 stores.
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-end px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <div className="rounded-md shadow">
                    <Link
                      to="/signup/ONE_MONTH"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600"
                      aria-describedby="tier-standard"
                    >
                      Purchase
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
