import React, { useContext, useState } from 'react'
import Plausible from 'plausible-tracker'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import { createFileName, downloadBlob, fromLocalStorage } from '../../../common'
import InfoNote from '../../InfoNote'
import useCategoriesFilter from '../../export/useCategoriesFilter'
import useBrandsFilter from '../../export/useBrandsFilter'

const { trackEvent } = Plausible()

const STORAGE_KEY = 'productImagesOptions'

/*
"id": 485,
"product_id": 158,
"is_thumbnail": false,
"sort_order": 1,
"description": "",
"image_file": "o/381/product-image__98178.png",
"url_zoom": "https://cdn8.bigcommerce.com/s-id30h7ohwf/products/158/images/485/product-image__98178.1536854227.1280.1280.png?c=2",
"url_standard": "https://cdn8.bigcommerce.com/s-id30h7ohwf/products/158/images/485/product-image__98178.1536854227.560.850.png?c=2",
"url_thumbnail": "https://cdn8.bigcommerce.com/s-id30h7ohwf/products/158/images/485/product-image__98178.1536854227.330.500.png?c=2",
"url_tiny": "https://cdn8.bigcommerce.com/s-id30h7ohwf/products/158/images/485/product-image__98178.1536854227.66.100.png?c=2",
"date_modified": "2018-09-13T15:57:07+00:00"
*/

const columns = {
  ID: true,
  PRODUCT_ID: true,
  PRODUCT_NAME: true,
  SKU: true,
  MPN: true,
  GTIN: true,
  UPC: true,
  is_thumbnail: true,
  sort_order: true,
  description: true,
  image_url: true,
  IMAGE_FILE: true,
  URL_ZOOM: true,
  URL_STANDARD: true,
  URL_THUMBNAIL: true,
  URL_TINY: true,
  DATE_MODIFIED: true,
}

const requiredColumns = ['ID', 'PRODUCT_ID']

const ProductsDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { categoriesPanel, selectedCategories } = useCategoriesFilter()
  const { brandsPanel, selectedBrands } = useBrandsFilter()

  /* "Remember" the user's column seclections */
  const getColumnDefaultsFromLocalStorage = () => {
    const savedColumns = fromLocalStorage(STORAGE_KEY) || {}
    return {
      ...columns,
      ...savedColumns,
    }
  }

  const [formData, setFormData] = useState(getColumnDefaultsFromLocalStorage())
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const onCheck = (event) => {
    const { id, checked } = event?.target
    setFormData((formData) => ({ ...formData, [id]: checked }))
    setError(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Products download')
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/product-images/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({
            columns: formData,
            categories: selectedCategories.map((e) => e.id),
            brands: selectedBrands.map((e) => e.id),
          }),
        }
      )
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName('product-images', storesCtx.store.bc_hash, 'csv')
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="image" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-center">
            Download Product Images
            <span className="ml-2 px-4 py-1 text-sm text-white bg-yellow-500 rounded-lg">
              BETA
            </span>
          </div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="pt-4">
          <div className="text-sm text-gray-500">
            Select the columns you want to see in your CSV file.
          </div>
          <InfoNote title="Read-only columns">
            The all uppercase columns will contain read-only values. You can't
            change these values upon import.
          </InfoNote>
        </div>

        <div className="mt-6 grid grid-cols-2">
          {Object.keys(columns).map((id) => (
            <Checkbox
              key={id}
              id={id}
              value={formData[id]}
              onChange={onCheck}
              disabled={requiredColumns.includes(id)}
            />
          ))}
        </div>

        <div className="-mx-4 mt-4 p-4 bg-gray-100 rounded-md flex">
          {categoriesPanel}
          {brandsPanel}
        </div>

        {error && <Error msg={error} />}

        <div className="mt-2">
          <span className="block w-full py-4">
            <button
              onClick={runTool}
              disabled={disabled}
              type="button"
              className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                disabled ? 'bg-gray-500' : 'bg-blue-600'
              } ${
                !disabled && 'hover:bg-blue-500'
              } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
            >
              {waiting ? (
                <SVG name="spinner" size={32} />
              ) : (
                <SVG name="download" size={32} lineWidth={1} />
              )}
              <div className="ml-2">Download</div>
            </button>
          </span>
        </div>
        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                It may take several seconds for your CSV file to be generated
                and start downloading.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductsDownload
