import React, { useContext, useState } from 'react'
import Plausible from 'plausible-tracker'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import InfoNote from '../../InfoNote'
import { createFileName, downloadBlob, fromLocalStorage } from '../../../common'

const { trackEvent } = Plausible()

const STORAGE_KEY = 'variantColumns'

const columns = {
  PRODUCT_ID: true,
  PRODUCT_SKU: true,
  PRODUCT_NAME: true,
  VARIANT_ID: true,
  bin_picking_number: true,
  CALCULATED_PRICE: true,
  CALCULATED_WEIGHT: true,
  cost_price: true,
  depth: true,
  fixed_cost_shipping_price: true,
  gtin: true,
  height: true,
  image_url: true,
  inventory_level: true,
  inventory_warning_level: true,
  is_free_shipping: true,
  map_price: true,
  mpn: true,
  OPTIONS: true,
  price: true,
  purchasing_disabled: true,
  purchasing_disabled_message: true,
  retail_price: true,
  sale_price: true,
  sku: true,
  upc: true,
  weight: true,
  width: true,
}

const requiredColumns = ['VARIANT_ID', 'PRODUCT_ID']

const VariantsDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)

  /* "Remember" the user's column seclections */
  const getColumnDefaultsFromLocalStorage = () => {
    const savedColumns = fromLocalStorage(STORAGE_KEY) || {}
    return {
      ...columns,
      ...savedColumns,
    }
  }

  const [formData, setFormData] = useState(getColumnDefaultsFromLocalStorage())
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const onCheck = (event) => {
    const { id, checked } = event?.target
    setFormData((formData) => ({ ...formData, [id]: checked }))
    setError(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Variants download')
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/variants/export`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({
            columns: formData,
          }),
        }
      )
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName('variants', storesCtx.store.bc_hash, 'csv')
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="variant" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-center">
            Download Variants{' '}
            <span className="ml-2 px-4 py-1 text-sm text-white bg-yellow-500 rounded-lg">
              BETA
            </span>
          </div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="pt-4">
          <div className="text-sm text-gray-500">
            Select the columns you want to see in your CSV file.
          </div>
          <InfoNote title="Read-only columns">
            The all uppercase columns will contain read-only values. You can't
            change these values upon import with this tool.
          </InfoNote>
        </div>

        <div className="mt-6 grid grid-cols-2">
          {Object.keys(columns).map((id) => (
            <Checkbox
              key={id}
              id={id}
              value={formData[id]}
              onChange={onCheck}
              disabled={requiredColumns.includes(id)}
            />
          ))}
        </div>

        {error && <Error msg={error} />}

        <div className="mt-2">
          <span className="block w-full py-4">
            <button
              onClick={runTool}
              disabled={disabled}
              type="button"
              className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                disabled ? 'bg-gray-500' : 'bg-blue-600'
              } ${
                !disabled && 'hover:bg-blue-500'
              } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
            >
              {waiting ? (
                <SVG name="spinner" size={32} />
              ) : (
                <SVG name="download" size={32} lineWidth={1} />
              )}
              <div className="ml-2">Download</div>
            </button>
          </span>
        </div>
        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                It may take several seconds for your CSV file to be generated
                and start downloading.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default VariantsDownload
