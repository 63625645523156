import { useContext, useState, useEffect, useCallback } from 'react'
import { UserContext } from '../Context/User'
import { StoresContext } from '../Context/Stores'
import { API_URL, fetchCfg } from '../../config'

export default function useGetEntities(entityType) {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { store } = storesCtx

  const [entities, setEntities] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const getEntities = useCallback(async () => {
    setLoading(true)
    let res
    try {
      res = await fetch(`${API_URL}/${store.bc_hash}/${entityType}`, {
        ...fetchCfg(user.csrfToken),
        method: 'get',
      })
      const json = await res.json()
      if (res.status === 200) {
        setEntities(json)
        return
      } else {
        console.error(json)
        setError(json.error)  
      }
    } catch (err) {
      console.error(res)
      setError(`Failed to fetch ${entityType}`)
    } finally {
      setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.bc_hash, user.csrfToken])

  useEffect(() => {
    if (store?.bc_hash && !entities.length) {
      getEntities()
    }
  }, [store, entities, getEntities])

  return {
    entities,
    error,
    loading,
  }
}
