import React from 'react'
import SVG from './SVG'

const InfoNote = ({ title, children }) => (
  <div className="flex">
    <span className="text-gray-500 pt-2">
      <SVG name="info" size={16} />
    </span>
    <div className="ml-3">
      <p className="text-xs text-gray-500">
        <strong>{title}</strong>&nbsp; {children}
      </p>
    </div>
  </div>
)

export default InfoNote
