import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductsDownload from './ProductsDownload'
import ProductsImport from './ProductsImport'

export default function ProductsManagePage() {
  return (
    <ToolPageLayout2col>
      <ProductsDownload />
      <ProductsImport />
    </ToolPageLayout2col>
  )
}
