import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import RedirectsDownload from './RedirectsDownload'
import RedirectsImport from './RedirectsImport'

export default function CategoriesManagePage() {
  return (
    <ToolPageLayout2col>
      <RedirectsDownload />
      <RedirectsImport />
    </ToolPageLayout2col>
  )
}
