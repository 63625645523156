import React, { useContext } from 'react'
import { UserContext } from '../Context/User'
import { StoresContext } from '../Context/Stores'
import SVG from '../SVG'

const ToolPageLayout2col = ({ children }) => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const loggedIn = !user.authenticated || !storesCtx.store.bc_hash
  return (
    <main className="bg-gray-50">
      <div className="bg-indigo-600">
        <div className="max-w-7xl mx-auto h-40 px-4 sm:px-6 lg:px-8 text-indigo-300 text-5xl font-semibold border-t border-indigo-500"></div>
      </div>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 -mt-36">
        {loggedIn && (
          <div className="bg-yellow-50 px-4 py-2 rounded-md mb-6 -mt-4">
            <div className="flex items-center">
              <span className="text-yellow-600">
                <SVG name="warning" size={24} />
              </span>
              <div className="ml-3 ">
                <p className="text-sm text-yellow-600">
                  You must be logged in and have selected a store before you can
                  use this tool.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="md:flex items-stretch md:space-x-6 lg:space-x-8">
          <div className="md:w-2/3 bg-white rounded-lg p-6 shadow">
            {children[0]}
          </div>
          <div className="w-full md:w-1/3 bg-white rounded-lg p-6 shadow mt-6 md:mt-0 flex flex-col">
            {children[1]}
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="px-6 py-4 sm:px-0"></div>
        </div>
      </div>
    </main>
  )
}

export default ToolPageLayout2col
