import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductImagesDownload from './ProductImagesDownload'
import ProductImagesImport from './ProductImagesImport'

export default function ProductsManagePage() {
  return (
    <ToolPageLayout2col>
      <ProductImagesDownload />
      <ProductImagesImport />
    </ToolPageLayout2col>
  )
}
