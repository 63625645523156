import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductTemplatesDownload from './ProductTemplatesDownload'
import TemplatesImport from './TemplatesImport'

export default function ProductTemplatesManagePage() {
  return (
    <ToolPageLayout2col>
      <ProductTemplatesDownload />
      <TemplatesImport />
    </ToolPageLayout2col>
  )
}
