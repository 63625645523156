import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  entities: 'categories in store',
  updated: 'categories updated',
  created: 'categories created',
  deleted: false,
  validated: false,
}

const CategoriesImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Categories import',
      method: 'categories/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="Won't delete">
              Import process <em>will not</em>&nbsp;delete any categories.{' '}
            </InfoNote>
            <InfoNote title="Have a backup?">
              Make sure you have a{' '}
              <span className="font-bold">recent, unmodified CSV file</span> in
              case you need to restore your data.{' '}
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default CategoriesImport
