import React from 'react'

const Privacy = () => {

  return (
    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

        <h1>Privacy</h1>
        <ul>
          <li>We do not share your information with anyone.</li>
          <li>We store only what we need to service your account.</li>
          <li>We do use standard web analytics that record anonymous data about operating systems, browsers, and geolocation.</li>
          <li>You may request account deletion at any time by request to support@bctools.io </li>
        </ul>

      </div>
    </main>
  )
}

export default Privacy
