import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import { createFileName, downloadBlob } from '../../../common'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible()

const BrandTemplatesDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)

  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Brand Templates download')
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    try {
      const res = await fetch(
        `${API_URL}/${storesCtx.store.bc_hash}/templates/export/brand`,
        {
          ...fetchCfg(user.csrfToken),
          method: 'post',
          body: JSON.stringify({ entityProps: ['name'] }),
        }
      )
      if (res.status !== 200) {
        throw new Error(res.status)
      }
      const fileBlob = await res.blob()
      downloadBlob(
        fileBlob,
        createFileName('brand-templates', storesCtx.store.bc_hash, 'csv')
      )
    } catch (err) {
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !storesCtx.store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="template" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">Brand Template assignments</div>
        </h5>
      </div>

      <form
        method="post"
        action="#"
        onSubmit={handleSubmit}
        className="w-full "
      >
        {error && <Error msg={error} />}

        <div className="mt-2 flex items-center justify-center h-60">
          <button
            onClick={runTool}
            disabled={disabled}
            type="button"
            className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
              disabled ? 'bg-gray-500' : 'bg-blue-600'
            } ${
              !disabled && 'hover:bg-blue-500'
            } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
          >
            {waiting ? (
              <SVG name="spinner" size={32} />
            ) : (
              <SVG name="download" size={32} lineWidth={1} />
            )}
            <div className="ml-2">Download</div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default BrandTemplatesDownload
