import React from 'react'
import { Link } from 'react-router-dom'
// import React, { useContext } from 'react'
// import { UserContext } from './Context/User'

const Home = (props) => {
  // const user = useContext(UserContext)

  return (
    <main>
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48 bg-indigo-600">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-20">
              <div className="hidden">
                <a href="/" className="inline-flex space-x-4">
                  <span className="rounded bg-yellow-50 px-2.5 py-1 text-xs font-semibold text-yellow-500 tracking-wide uppercase">
                    What's new
                  </span>
                  <span className="inline-flex items-center text-sm font-medium text-yellow-500 space-x-1">
                    <span>Just shipped version 0.1.0</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
                  The missing tools for BigCommerce
                </h1>
                <p className="mt-6 text-xl text-indigo-200">
                  BigCommerce is a great system, but managing your data for
                  large stores can be difficult and time consuming. We aim to
                  fix that.
                </p>
              </div>
              <div className="mt-6">
                <div className="inline-flex items-center divide-x divide-indigo-500">
                  <div className="flex-shrink-0 flex pr-5">
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </div>
                  <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-indigo-400 sm:py-3">
                    <span className="font-medium text-indigo-400">
                      Rated 5 stars
                    </span>{' '}
                    by our mothers
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 -bottom-8 left-1/2 w-screen bg-indigo-500 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0 opacity-20"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>

            <Link
              to="/tools"
              className="block relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12 hover:scale-[1.02] transition"
            >
              <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src="/img/site.jpg"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="hidden relative mt-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src="https://images.unsplash.com/photo-1521510895919-46920266ddb3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&fp-x=0.5&fp-y=0.6&fp-z=3&width=1440&height=1440&sat=-100"
                  alt=""
                />
                <div className="absolute inset-0 bg-yellow-500 mix-blend-multiply"></div>
                <div className="absolute inset-0 bg-gradient-to-t from-yellow-600 via-yellow-600 opacity-90"></div>
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-yellow-400 opacity-30"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="relative text-white text-2xl">
                        Managing over 60 brands was a nightmare before I found
                        BCtools!
                      </p>
                    </div>

                    <footer className="mt-4">
                      <p className="text-base font-semibold text-yellow-200">
                        Sarah, CEO
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Manage faster, sell more.
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-lg">
                  Do you have a lot of products on your BigCommerce store, but
                  find managing them takes too much time?
                </p>
                <p className="text-base leading-7">
                  We were in the same boat. So we built a suite of tools that
                  taps into BigCommerce's APIs and that allows for something the
                  default admin can't do very well... bulk editing.
                </p>
                <p className="text-base leading-7">
                  We're not done yet, either. New tools are regularly being
                  added. Have an idea for a tool? Let us know and we may be able
                  to make your dreams come true.
                </p>
              </div>
            </div>

            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    1. Sign up
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Pick a plan
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    2. Setup
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Add store
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    3. Download CSV
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Extract to edit
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    4. Import
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Publish
                  </dd>
                </div>
              </dl>
              <div className="mt-10">
                <a
                  href="/tools"
                  className="text-base font-medium text-yellow-500"
                >
                  See our current list of tools &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-20">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src="https://player.vimeo.com/video/646604162?h=4e3628c755&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  title="BCtools.io"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Manage faster, sell more.
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-base leading-7">
                  We built a suite of tools that taps into the BigCommerce APIs
                  to give you tools that make working with your data
                  easier--like the ability to bulk-edit your categories and
                  brands.
                </p>
                <p className="text-base leading-7">
                  We're not done yet, either. New tools are being added. Have an
                  idea for a tool? Let us know and we may be able to make your
                  dreams come true.
                </p>
              </div>
            </div>

            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    1. Sign up
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    <Link
                      to="/pricing"
                      className="text-indigo-900 hover:text-indigo-700"
                    >
                      Pick a plan
                    </Link>
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    2. Setup
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Add store
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    3. Download CSV
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Extract to edit
                  </dd>
                </div>

                <div className="border-t-2 border-gray-100 pt-6">
                  <dt className="text-base font-medium text-gray-500">
                    4. Import
                  </dt>
                  <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Publish
                  </dd>
                </div>
              </dl>
              <div className="mt-10">
                <a
                  href="/tools"
                  className="text-base font-medium text-yellow-500"
                >
                  See our current list of tools &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-32 pb-16 bg-gradient-to-r from-indigo-500 to-indigo-800 lg:pb-0 lg:z-10 lg:relative">
        <div className="pt-4 lg:pt-0 lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="relative lg:-my-8 hidden lg:block">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
              <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <img
                  className="object-cover lg:h-full lg:w-full"
                  src="https://images.unsplash.com/photo-1572021335469-31706a17aaef?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2670&q=80"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
              <blockquote>
                <div>
                  <svg
                    className="h-12 w-12 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-2xl font-medium text-white">
                    BCtools has been a huge help in managing our store. With
                    over 2000 products and close to 100 categories, BCtools has
                    enabled us to manage bulk updates in a way that is simply
                    not possible with BigCommerce native capabilities!
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-white">
                    Jonathan Forgacs
                  </p>
                  <p className="text-base font-medium text-indigo-100">
                    President at{' '}
                    <a
                      className="text-base font-medium text-yellow-500"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.pillowdecor.com/"
                    >
                      Pillow Décor Ltd
                    </a>
                    .
                  </p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-24 sm:mt-32 sm:py-16">
        <div aria-hidden="true" className="hidden sm:block">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100 rounded-tr-3xl rounded"></div>
          <svg
            className="absolute top-8 left-1/2 -ml-3"
            width="404"
            height="392"
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="392"
              fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
            />
          </svg>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-indigo-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div
              aria-hidden="true"
              className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
            >
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-indigo-400 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-indigo-600 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  Ready to save time?
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-100 pb-4">
                  Whether you need to update your category meta keys weekly to
                  stay on top of your SEO strategy or just need to export some
                  data today, we have a plan built for you.
                </p>
              </div>
              <Link
                to="/pricing"
                className="mt-12 sm:mx-auto sm:max-w-lg sm:flex items-center justify-center px-5 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-800"
              >
                Pick a plan
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Home
