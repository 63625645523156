import "react-app-polyfill/ie11"
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import { UserProvider } from './components/Context/User'
import { StoresProvider } from './components/Context/Stores'
import * as serviceWorker from './serviceWorker'
import './tailwind.generated.css'
import Plausible from 'plausible-tracker'

// A global object we can use.
window.BCtools = window.BCtools || {}

const { enableAutoPageviews } = Plausible({
  domain: 'bctools.io'
})
// This tracks the current page view and all future ones as well
enableAutoPageviews()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <StoresProvider>
          <App />
        </StoresProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
