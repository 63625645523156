import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/User'
import { StoresContext } from '../../Context/Stores'
import { API_URL, fetchCfg } from '../../../config'
import SVG from '../../SVG'
import Error from '../../Error'
import Checkbox from '../../Checkbox'
import { createFileName, downloadBlob, fromLocalStorage } from '../../../common'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible()

const CategoriesDownload = () => {
  const user = useContext(UserContext)
  const storesCtx = useContext(StoresContext)
  const { store } = storesCtx

  /* "Remember" the user's column seclections */
  const getColumnDefaultsFromLocalStorage = () => {
    const savedColumns = fromLocalStorage('categoryColumns') || {}
    delete savedColumns.layout_file // TODO: can eventually remove this
    delete savedColumns.template
    delete savedColumns.parent_id
    return {
      name: true,
      description: false,
      views: true,
      sort_order: true,
      page_title: true,
      meta_keywords: true,
      meta_description: true,
      image_url: true,
      is_visible: true,
      search_keywords: true,
      default_product_sort: true,
      custom_url: true,
      PRODUCT_COUNT: false,
      ...savedColumns,
    }
  }

  const [formData, setFormData] = useState(getColumnDefaultsFromLocalStorage())
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)

  const handleCheck = (event) => {
    const { id, checked } = event?.target
    setFormData((formData) => ({ ...formData, [id]: checked }))
    setError(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Categories download')
    localStorage.setItem('categoryColumns', JSON.stringify(formData))
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(`${API_URL}/${store.bc_hash}/categories/export`, {
        ...fetchCfg(user.csrfToken),
        method: 'post',
        body: JSON.stringify(formData),
      })
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(
          fileBlob,
          createFileName('categories', store.bc_hash, 'csv')
        )
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="categories" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">
            Categories
            {store.summary && (
              <span className="bg-gray-200 rounded text-sm px-2 text-gray-600 ml-2">
                {store.summary.categories_count?.toLocaleString()}
              </span>
            )}
          </div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="pt-4">
          <div className="font-bold text-lg">Download categories</div>
          <div className="text-sm text-gray-500">
            Columns to include in download...
          </div>
        </div>

        <div className="mt-6 grid grid-cols-2">
          <Checkbox
            key="id"
            id="id"
            value={true}
            onChange={handleCheck}
            disabled={true}
          />
          <Checkbox
            key="parent_id"
            id="parent_id"
            value={true}
            onChange={handleCheck}
            disabled={true}
          />
          {Object.keys(formData).map((id) => (
            <Checkbox
              key={id}
              id={id}
              label={id === 'PRODUCT_COUNT' ? 'PRODUCT_COUNT (will take longer)' : id}
              value={formData[id]}
              onChange={handleCheck}
            />
          ))}
        </div>

        {/* <div className="flex-1 p-4 md:ml-20">
          <p className="text-sm leading-6 text-gray-700">
            Learn about each of the fields and their restrictions by expanding
            "Schema" in the "Request Body" section of the{' '}
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.bigcommerce.com/api-reference/catalog/catalog-api/category/updatecategory"
            >
              BigCommerce Update Category documentation
            </a>
            .
          </p>
        </div> */}

        {error && <Error msg={error} />}

        <div className="mt-2">
          <div className="w-full py-4">
            <button
              onClick={runTool}
              disabled={disabled}
              type="button"
              className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
                disabled ? 'bg-gray-500' : 'bg-blue-600'
              } ${
                !disabled && 'hover:bg-blue-500'
              } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
            >
              {waiting ? (
                <SVG name="spinner" size={32} />
              ) : (
                <SVG name="download" size={32} lineWidth={1} />
              )}
              <div className="ml-2">Download</div>
            </button>
          </div>
        </div>

        <div className="px-1 py-2 rounded-md opacity-70">
          <div className="flex items-center">
            <span className="text-gray-500">
              <SVG name="info" size={16} />
            </span>
            <div className="ml-3">
              <p className="text-xs text-gray-500">
                It may take several seconds for your CSV file to be generated
                and start downloading.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CategoriesDownload

/* Complicated streamsaver solution that fails in Firefox 
    const downloadFile = () => {
      const fileStream = streamSaver.createWriteStream(createFileName('categories', storesCtx.store.bc_hash, 'csv'))
  
      fetch(`${API_URL}/${storesCtx.store.bc_hash}/categories/export`, {
        ...fetchCfg(user.csrfToken),
        method: 'post',
        body: JSON.stringify(formData)
      }).then(res => {
        const readableStream = res.body
  
        // more optimized
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream)
            .then(() => console.log('done writing'))
        }
  
        window.writer = fileStream.getWriter()
  
        const reader = res.body.getReader()
        const pump = () => reader.read()
          .then(res => res.done
            ? window.writer.close()
            : window.writer.write(res.value).then(pump))
  
        pump()
      })
    } */

/* Using streamSaver instead
  const XdownloadFile = () => {
    let url = `${API_URL}/categories/${storesCtx.store.bc_hash}`
    let element = document.createElement('a')
    element.setAttribute('href', url)
    element.setAttribute('download', `categories.csv`)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
  */
