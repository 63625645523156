/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { useParams } from 'react-router-dom'
import SignupForm from './Login/SignupForm'

const PRICE_TYPES = new Set(['ONE_MONTH', 'ANNUAL'])
export default function Signup() {
  const { priceType } = useParams()
  if (!PRICE_TYPES.has(priceType)) {
    window.location = '/pricing'
  }
  return (
    <div className="p-12 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="py-4">
        {priceType === 'ONE_MONTH' ? (
          <div>
            <h1>$25 one time purchase</h1>
            30 days of access to the tools for up to 3 stores
          </div>
        ) : (
          <div>
            <h1>$99/year</h1>
            Annual subscription for up to 3 stores
          </div>
        )}
      </div>
      <SignupForm priceType={priceType} />
    </div>
  )
}
