import React from "react"

const NotFound = () => {

  return (
    <div className="m-24 text-4xl">
      <h1>page <em>not</em> found</h1>
      <a href="/">Try our homepage</a>
    </div>
  )
}

export default NotFound