import React from 'react'
import ToolPageLayout from '../ToolPageLayout'
import OrdersDownload from './OrdersDownload'

const OrdersDownloadPage = () => {

  return (
    <ToolPageLayout>
      <OrdersDownload />
    </ToolPageLayout>
  )
}

export default OrdersDownloadPage
