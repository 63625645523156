import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  entities: 'pages in store',
  updated: 'pages updated',
  deleted: false,
}

const PagesImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Pages import',
      method: 'pages/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="Have a backup?">
              Make sure you have a{' '}
              <span className="font-bold">recent, unmodified CSV file</span> in
              case you need to restore your data.{' '}
            </InfoNote>
            <InfoNote title='"blog" page'>
              Currently pages of type "blog" are not able to be updated via the
              BigCommerce API. Remove "blog" rows from your import CSV file.
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default PagesImport
