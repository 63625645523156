import React, { useState, useContext } from 'react'
import { StoresContext } from '../Context/Stores'
import SelectEntities from './SelectEntities'
import Modal from '../Modal'
import SVG from '../SVG'
import { fromLocalStorage } from '../../common'

const useEntityFilter = (entityType) => {
 
  const storesCtx = useContext(StoresContext)
  const [showSelector, setShowSelector] = useState(false)

  const entityTypeInitCap = `${entityType[0].toUpperCase()}${entityType.slice(1)}`

  const STORAGE_KEY = `${storesCtx.store.bc_hash}Selected${entityTypeInitCap}`

  const [selectedEntities, setSelectedEntities] = useState(
    fromLocalStorage(STORAGE_KEY) || []
  )
  const uniqueEntities = [
    ...new Set(selectedEntities.map((c) => c.name)),
  ].sort()

  const onSelection = (entities) => {
    setSelectedEntities(entities)
    localStorage.setItem(STORAGE_KEY, JSON.stringify(entities))
  }

  const filterPanel = (
    <>
      <button
        type="button"
        className="text-left bg-gray-100 p-4 border border-gray-100 rounded-lg items-start flex flex-col flex-1 hover:bg-blue-100 hover:border-blue-300 transition group"
        onClick={() => setShowSelector(true)}
      >
        <div className="font-bold text-lg">Limit {entityType}</div>
        <div className="text-sm text-gray-500 pb-6">
          Download just products from these {entityType}
        </div>

        <>
          {uniqueEntities.length < 1 && (
            <div className="text-center w-full flex-1 flex justify-center items-center">
              <span className="text-gray-400 flex flex-col items-center ">
                <SVG name={entityType} size={50} lineWidth={1.25} />
                No {entityType} selected
              </span>{' '}
            </div>
          )}
          {uniqueEntities.map((catName) => (
            <div
              key={catName}
              className="text-left text-sm my-1 pl-2 pr-1 py-1 border rounded-md group self-start"
            >
              {catName}
            </div>
          ))}
        </>
      </button>
      <Modal
        open={showSelector}
        setOpen={setShowSelector}
        icon={entityType}
        title={`Select ${entityTypeInitCap}`}
      >
        <SelectEntities
          entityType={entityType}
          selectedItems={selectedEntities}
          onSelection={onSelection}
        />
      </Modal>
    </>
  )

  return {
    filterPanel,
    selectedEntities,
  }
}

export default useEntityFilter
