import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  entities: false,
  updated: 'reviews updated',
  created: 'reviews created',
  deleted: 'reviews deleted',
  validated: 'reviews validated',
}

const ProductReviewsImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Product Reviews import',
      method: 'products/reviews/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="Delete">
              If you want to delete a review, set the status to "delete"
            </InfoNote>
            <InfoNote title="status">
              can be one of approved, disapproved, pending, delete
            </InfoNote>
            <InfoNote title="rating">can be one of 0,1,2,3,4,5</InfoNote>
            <InfoNote title="create a new review">
              Minimum required fields are product_id, status, rating, title
            </InfoNote>
            <InfoNote title="date format">
              is like this in UTC: 2022-10-24T08:35:01+00:00
            </InfoNote>
            <InfoNote title="questions?">
              This import tools is NEW. Please don't hesitate to reach out to{' '}
              <a className="link" href="support@bctools.io">support@bctools.io</a> with questions.
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default ProductReviewsImport
