import React from 'react'

const Instructions = () => (
  <div>
    <p className="mt-2 mb-4 text-sm text-center leading-6 text-gray-500">
      Modify downloaded CSV file, then import here.
    </p>
  </div>
)

export default Instructions
