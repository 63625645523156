import React from 'react'
import SVG from '../SVG'

const DropZone = ({ disabled }) => (
  <div className="flex-1 flex flex-col">
    <div
      className={`flex-1 flex items-center justify-center p-4 border-2 border-dashed border-gray-200 text-lg text-gray-500 font-medium rounded-md ${
        disabled ? 'opaity-30' : ''
      } ${
        !disabled && 'hover:bg-gray-50 hover:text-gray-900'
      } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:border-blue-700 transition duration-150 ease-in-out`}
    >
      <SVG name="upload" size={32} lineWidth={1} />
      <div className="ml-2">Import .CSV</div>
    </div>
  </div>
)

export default DropZone
