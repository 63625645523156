import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import ProductsCustomFieldsDownload from './ProductsCustomFieldsDownload'
import ProductsCustomFieldsImport from './ProductsCustomFieldsImport'

export default function CustomFieldsManagePage() {
  return (
    <ToolPageLayout2col>
      <ProductsCustomFieldsDownload />
      <ProductsCustomFieldsImport />
    </ToolPageLayout2col>
  )
}
