import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import PagesDownload from './PagesDownload'
import PagesImport from './PagesImport'

export default function BrandsManagePage() {
  return (
    <>
      <ToolPageLayout2col>
        <PagesDownload />
        <PagesImport />
      </ToolPageLayout2col>
    </>
  )
}
