import React, { useState, useContext } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { UserContext } from '../Context/User'
import Error from './Error'
import Instructions from './Instructions'
import DropZone from './DropZone'

// "react-drag-drop-files": "^2.3.2",
// "react-drag-drop-files": "../../../contrib/react-drag-drop-files",

const FILE_TYPES = ['CSV']

function useFileDragDrop({ method }) {
  const user = useContext(UserContext)

  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)

  const reset = () => {
    setError(null)
    setFile(null)
    user[method] = {}
  }

  const handleChange = (file) => {
    if (user.authenticated) {
      setFile(file)
    }
  }

  const onTypeError = (err) => {
    setError(err)
  }

  const FileSelector = ({ disabled, launched = true }) => (
    <div className="flex flex-col flex-1">
      {!launched && <Error msg="Import not supported yet - COMING SOON" />}
      {launched && error && <Error msg="Only CSV files accepted for import" />}
      {launched && !error && <Instructions />}

      <FileUploader
        handleChange={handleChange}
        name="file"
        types={FILE_TYPES}
        onTypeError={onTypeError}
        disabled={disabled}
        hoverTitle=" "
        classes={`flex-1 flex flex-col ${!launched ? 'opacity-10' : ''}`}
      >
        <DropZone disabled={disabled} />
      </FileUploader>
    </div>
  )

  return {
    file,
    FileSelector,
    reset,
  }
}

export default useFileDragDrop
