import React from 'react'
import SVG from '../SVG'

const Error = ({msg}) => (
  <div className="bg-red-500 px-4 py-2 rounded-md mb-4">
    <div className="flex items-center">
      <span className="text-red-200">
        <SVG name="warning" size={20} lineWidth={2.5} />
      </span>
      <div className="ml-3">
        <p className="text-sm text-white">{msg?.message ? JSON.stringify(msg) : msg}</p>
      </div>
    </div>
  </div>
)

export default Error