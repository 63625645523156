import * as React from 'react'
import { UserContext } from './Context/User'
import Error from './Error'
import SVG from './SVG'

const Login = ({ history }) => {
  const user = React.useContext(UserContext)
  // Already logged in so redirect to tools
  React.useEffect(() => {
    if (user.authenticated) {
      history.push('/tools')
    }
  }, [user.authenticated, history])

  const [email, setEmail] = React.useState('')
  const [formDirty, setFormDirty] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormDirty(false)
    await user.sendLoginEmail(email)
    if (user.actionError) {
      return
    }
    setEmailSent(true)
  }

  return (
    <main className="bg-indigo-600 flex-1 flex min-h-[calc(100vh-64px-88px)]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-indigo-300 border-t border-indigo-500 flex flex-col items-center justify-center gap-6 py-8 flex-1">
        {!user.actionError && emailSent ? (
          <div className="flex flex-col items-center">
            <SVG name="emailLink" size="200px" />
            <h1 className="text-white">Check your email</h1>
            <div className="max-w-xs text-center py-1">
              We sent you an email with a link you can use to sign into BCtools.
            </div>
          </div>
        ) : (
          <>
            <SVG name="logo" className="w-[220px] text-indigo-300" />
            <div className="p-4 lg:w-1/3 md:p-10 rounded-lg bg-white text-left shadow sm:rounded-lg">
              <p className="pb-4 text-gray-500 text-center border-b border-gray-200 mb-8">
                We'll send you an email with a link to sign you in.
              </p>
              <form method="post" action="#" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="email"
                      type="text"
                      value={email}
                      onChange={(evt) => {
                        setEmail(evt.target.value)
                        setFormDirty(true)
                      }}
                      autoComplete="username"
                      required
                      className="appearance-none text-gray-800  block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>

                {!formDirty && !user.isAuthEmailPending && user.actionError && (
                  <Error msg={user.actionError} />
                )}

                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    <button
                      disabled={user.isAuthEmailPending}
                      type="submit"
                      className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-2xl md:px-10 ${
                        user.isAuthEmailPending ? 'opacity-30' : ''
                      }`}
                    >
                      Sign in with email
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </main>
  )
}

export default Login
