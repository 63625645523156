import { format } from 'date-fns'
import * as React from 'react'
import { UserContext } from '../../../Context/User'
import { StoresContext } from '../../../Context/Stores'
import { API_URL, fetchCfg } from '../../../../config'
import SVG from '../../../SVG'
import Error from '../../../Error'
import { createFileName, downloadBlob } from '../../../../common'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible()

const OrdersDownload = () => {
  const user = React.useContext(UserContext)
  const storesCtx = React.useContext(StoresContext)
  const { store } = storesCtx

  const [waiting, setWaiting] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [formData, setFormData] = React.useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  })


  const onChange = (e) => {
    const { target } = e
    setFormData((formData) => ({
      ...formData,
      [target.name]: target.value,
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
  }

  const runTool = async () => {
    setWaiting(true)
    trackEvent('Orders download')
    await downloadFile()
    setWaiting(false)
  }

  const downloadFile = async () => {
    setError(null)
    let res
    try {
      res = await fetch(`${API_URL}/${store.bc_hash}/sinnfein/orders/export`, {
        ...fetchCfg(user.csrfToken),
        method: 'post',
        body: JSON.stringify(formData),
      })
      if (res.status === 204) {
        setError('No awaiting shipment orders found for the time period')
        return
      }
      if (res.status === 200) {
        const fileBlob = await res.blob()
        downloadBlob(fileBlob, createFileName('orders', store.bc_hash, 'csv'))
        return
      }
      const data = await res.json()
      setError(data.error)
    } catch (err) {
      console.error(res)
      setError('Something went wrong')
    }
  }

  const disabled = !user.authenticated || !store.bc_hash

  return (
    <div className="download">
      <div className="flex-1 border-b border-gray-200 mb-4">
        <h5 className="text-3xl leading-6 font-semibold text-gray-900 flex items-center mt-0">
          <span className="text-indigo-600">
            <SVG name="order" size={54} lineWidth={1.5} />
          </span>
          <div className="pl-2 flex items-end">Sinn Féin Orders</div>
        </h5>
      </div>

      <form method="post" action="#" onSubmit={onSubmit}>
        {error && <Error msg={error} />}

        {/* Date pickers  */}
        <div>
          <div className="my-4">
            Include all <b>Awaiting Shipment</b> orders between and including these dates
          </div>
          <div className="inline-block">
            <input
              className="appearance-none block px-3 py-2 border border-blue-300 rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              name="startDate"
              type="date"
              value={formData.startDate}
              onChange={onChange}
            />
          </div>
          <div className="mx-4 inline-block">to</div>
          <div className="inline-block">
            <input
              className="appearance-none block px-3 py-2 border border-blue-300 rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              name="endDate"
              type="date"
              value={formData.endDate}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="mt-2 flex items-center  h-60">
          <button
            onClick={runTool}
            disabled={disabled}
            type="button"
            className={`flex items-center justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white ${
              disabled ? 'bg-gray-500' : 'bg-blue-600'
            } ${
              !disabled && 'hover:bg-blue-500'
            } focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out`}
          >
            {waiting ? (
              <SVG name="spinner" size={32} />
            ) : (
              <SVG name="download" size={32} lineWidth={1} />
            )}
            <div className="ml-2">Download</div>
          </button>
        </div>
      </form>
    </div>
  )
}

export default OrdersDownload
