import React from 'react'
import SVG from '../SVG'

const SelectedEntities = ({ entityType, entities, onRemove }) => {
  const uniqueEntities = [...new Set(entities.map((c) => c.name))].sort()

  return (
    <div className="p-4 sm:w-1/2 sm:order-2 flex flex-wrap">
      {uniqueEntities.length === 0 && (
        <div className="text-sm text-gray-400 pt-4 text-center w-full">
          No {entityType} selected{' '}
        </div>
      )}

      <div className="flex content-start flex-wrap gap-2">
        {uniqueEntities.map((name) => (
          <div key={name}>
            <button
              type="button"
              className="text-left text-sm pl-2 pr-1 py-1 border rounded-md hover:bg-red-100 hover:border-red-300 group self-start"
              onClick={() => onRemove(name)}
            >
              {name}
              <SVG
                name="trash"
                className="h-4 w-4 ml-1 text-gray-300 group-hover:text-red-500"
              />
            </button>
          </div>
        ))}
      </div>
      {uniqueEntities.length > 0 && (
        <div className="text-sm text-gray-400 pt-4 self-end text-center">
          Download products only in these selected {entityType}.{' '}
        </div>
      )}
    </div>
  )
}

export default SelectedEntities
