const fromLocalStorage = item => {
  try {
    return JSON.parse(window.localStorage.getItem(item))
  } catch (err) {
    window.localStorage.removeItem(item)
    return false
  }
}

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

const deleteCookie = (name) => setCookie(name, '', -1)

const getCookie = item => {
  const name = item + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const createFileName = (label, storeHash, ext = 'csv') => (
  `${label}_${storeHash}_${new Date().toISOString().replace(/\D/g, '').slice(0, 14)}.${ext}`
)

/* Copied from https://dev.to/nombrekeff/download-file-from-blob-21ho */
const downloadBlob = (blob, name = 'data.csv') => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob)

  // Create a link element
  const link = document.createElement("a")

  // Set link's href to point to the Blob URL
  link.href = blobUrl
  link.download = name

  // Append link to the body
  document.body.appendChild(link)

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  )

  // Remove link from body
  document.body.removeChild(link)
}

// Convert a group of checkbox props to a simple array of names.
// If the name is in the array, the checkbox is checked.
const createFieldArray = (props) =>
  Object.entries(props)
    .map(([key, val]) => (val ? key : null))
    .filter(Boolean)

export {
  fromLocalStorage,
  setCookie,
  deleteCookie,
  getCookie,
  createFileName,
  downloadBlob,
  createFieldArray,
}
