const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.bctools.io'
    : 'https://api.bctools.local'

const fetchCfg = (csrfToken) => ({
  method: 'get',
  mode: 'cors',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'csrf-token': csrfToken,
  },
})

export { API_URL, fetchCfg }
