import React from 'react'

const Checkbox = ({ id, label, value, onChange, disabled = false, asterisk = false }) => (
  <div className="mx-2 my-2 md:inline-block w-full truncate">
    <div className="flex items-center">
      <label
        htmlFor={id}
        className="text-sm flex items-center text-gray-900 hover:text-blue-600"
      >
        <input
          id={id}
          type="checkbox"
          className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
          onChange={onChange}
          value={value}
          defaultChecked={value}
          disabled={disabled}
        />
        <span className="pl-2">{label || id} {asterisk ? '*' : null}</span>
      </label>
    </div>
  </div>
)

export default Checkbox
