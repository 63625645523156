import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  entities: false,
  updated: 'redirects upserted',
  created: false,
  deleted: 'redirects deleted',
  validated: false,
}

const RedirectsImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Redirects import',
      method: 'redirects/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="type">
              can be one of product, brand, category, page, post, url
            </InfoNote>
            <InfoNote title="if type = url">
              then you must set a "to_url". Otherwise,
              "to_url" should be blank.
            </InfoNote>

            <InfoNote title="Delete">
              If you want to delete a redirect, set the "type" column to
              "delete".
            </InfoNote>
            <InfoNote title="About the results">
              The "deleted" count you'll see in the results does not necessarily
              indicate the redirect existed before your import.
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default RedirectsImport
