import React from 'react'
import useImport from '../../import'
import InfoNote from '../../InfoNote'

const resultMap = {
  entities: false,
  created: 'fields created',
  updated: 'fields changed',
  deleted: 'fields deleted',
}

const ProductsCustomFieldsImport = () => {
  const { FileSelector, FileImport, result, fileSelected, disabled } =
    useImport({
      event: 'Products custom fields import',
      method: 'products/custom-fields/import',
      resultMap,
    })

  return (
    <>
      {!result?.runId && !fileSelected && (
        <>
          <FileSelector disabled={disabled} />

          <div className="rounded-md opacity-70 mt-auto pt-4">
            <InfoNote title="Have a backup?">
              Make sure you have a{' '}
              <span className="font-bold">recent, unmodified CSV file</span> in
              case you need to restore your data.{' '}
            </InfoNote>
          </div>
        </>
      )}

      {(result?.runId || fileSelected) && <FileImport />}
    </>
  )
}

export default ProductsCustomFieldsImport
