import React from 'react'
import ToolPageLayout2col from '../ToolPageLayout2col'
import CategoryTemplatesDownload from './CategoryTemplatesDownload'
import TemplatesImport from './TemplatesImport'

export default function CategpryTemplatesManagePage() {
  return (
    <ToolPageLayout2col>
      <CategoryTemplatesDownload />
      <TemplatesImport />
    </ToolPageLayout2col>
  )
}
