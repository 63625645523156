import React, { useState, useContext } from 'react'
import { StoresContext } from './Context/Stores'
import { UserContext } from './Context/User'
import SVG from './SVG'
import Error from './Error'

const DEFAULT_VALUES = {
  bc_hash: '',
  api_token: '',
  agree: false,
}

const StoreForm = () => {
  const storesCtx = useContext(StoresContext)
  const user = useContext(UserContext)
  const [formData, setFormData] = useState(DEFAULT_VALUES)
  const [formDirty, setFormDirty] = useState(false)
  const [error, setError] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleChange = (event) => {
    const { id, value } = event?.target
    setFormData((prev) => ({ ...prev, [id]: value }))
    setError(null)
    setFormDirty(true)
  }

  const handleCheck = (event) => {
    const { id, checked } = event?.target
    setFormData((prev) => ({ ...prev, [id]: checked }))
    setError(null)
    setFormDirty(true)
  }

  const onHashBlur = (event) => {
    const { value } = event?.target
    const bits = value.split('/')
    const bc_hash = bits[4]
    if (bc_hash) {
      setFormData((prev) => ({ ...prev, bc_hash }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormDirty(false)
    if (user.props.isSubscribed && storesCtx.stores.length > 2) {
      if (!formData.agree) {
        setError('You must agree to the price change to add a new store')
        return
      }
    }
    const result = await storesCtx.saveStore(formData)
    if (result.error) {
      setError(result.error)
    } else {
      // setFormData(DEFAULT_VALUES)
      // TODO: select the new store if not already?
      setShowSuccess(true)
      setTimeout(() => {
        window.history.back()
      }, 2000)
    }
  }

  return (
    <div className="bg-white p-4 md:p-6 shadow rounded-lg ">
      <div className="">
        <h1 className="mb-8 text-3xl font-semibold">Add a store</h1>

        <form method="post" action="#" onSubmit={handleSubmit}>
          <div className="mt-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Store Hash{' '}
              <span className="hidden text-xs ml-2 text-gray-500">
                or API Path--hash will be extracted
              </span>
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="bc_hash"
                type="text"
                value={formData.bc_hash}
                onChange={handleChange}
                onBlur={onHashBlur}
                placeholder="Your store's hash ID. e.g. 57q61tb7r4"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="mt-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              ACCESS Token
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="api_token"
                type="text"
                value={formData.api_token}
                onChange={handleChange}
                placeholder="API access token"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          {user.props.isSubscribed && storesCtx.stores.length > 2 && (
            <div className="mt-6 p-4 bg-red-300 rounded-md">
              <div className="text-sm text-red-900">
                You have {storesCtx.stores.length} stores configured. Each
                additional store will increase your monthly subscription price
                by $2.00.
              </div>
              <div className="mt-4">
                <input
                  id="agree"
                  name="agree"
                  type="checkbox"
                  value="YES"
                  checked={formData.agree}
                  onChange={handleCheck}
                />
                <label
                  htmlFor="agree"
                  className="ml-2 text-sm font-medium leading-5 text-gray-700"
                >
                  Agree to subscription price change
                </label>
              </div>
            </div>
          )}

          {!formDirty && !storesCtx.saving && error && <Error msg={error} />}

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              {showSuccess ? (
                <button
                  disabled
                  type="submit"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 md:py-4 md:text-lg md:px-10"
                >
                  <SVG name="checkmark" size={30} color="#ffffff" />
                </button>
              ) : (
                <button
                  disabled={storesCtx.saving}
                  type="submit"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                  {storesCtx.saving ? (
                    <SVG name="spinner" size={30} color="#ffffff" />
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              )}
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StoreForm
