import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import SVG from '../SVG'
import useGetEntities from './useGetEntities'

const entityTypeLabelMap = {
  brands: 'brand',
  categories: 'category',
  'customer-groups': 'customer group',
}

const EntitySelect = ({ entityType, selectedEntity, setSelectedEntity }) => {
  const [filter, setFilter] = React.useState('')
  const [matchingEntities, setMatchingEntities] = React.useState([])

  const sortedMatchingEntities = matchingEntities.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  const handleFilterChange = ({ target: { value } }) => {
    setFilter(value.toLowerCase())
  }

  const unselectEntity = () => {
    clearFilter()
    setSelectedEntity()
  }

  const clearFilter = () => {
    setFilter('')
    setMatchingEntities([])
  }

  const filterEntities = () => {
    const regex = new RegExp(filter)
    setMatchingEntities(
      entities.filter(
        (entity) =>
          regex.test(entity.name.toLowerCase()) || `${entity.id}` === filter
      )
    )
  }

  React.useEffect(() => {
    if (
      filter.length > 1 ||
      (filter.length > 0 && filter.replace(/\D+/g, '') === filter)
    ) {
      filterEntities()
    } else {
      setMatchingEntities([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const { entities, error } = useGetEntities(entityType)

  return (
    <div className="my-8">
      {selectedEntity && (
        <div>
          <button
            type="button"
            className="text-left text-md pl-2 pr-1 py-1 border rounded-md hover:bg-red-100 hover:border-red-300 group self-start"
            onClick={unselectEntity}
          >
            {selectedEntity.name}
            <SVG
              name="trash"
              className="h-4 w-4 ml-1 text-gray-300 group-hover:text-red-500"
            />
          </button>
        </div>
      )}

      {!selectedEntity && (
        <div className="sm:w-1/2 bg-gray-50 rounded-lg">
          <div className="flex items-center border border-gray-400 rounded-md bg-white focus-within:outline focus-within:outline-offset-1 focus-within:outline-2 focus-within:outline-blue-300">
            <input
              type="text"
              className="p-0 px-2 bg-transparent focus:border-0 focus:outline-none flex-1"
              onChange={handleFilterChange}
              value={filter}
              placeholder={`start typing a ${entityTypeLabelMap[entityType]} name...`}
            />
            <button
              type="button"
              className="my-3 mx-2 text-gray-400 hover:text-red-500 ml-auto"
              onClick={clearFilter}
            >
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          {error && <div className="mt-4 p-4 bg-red-200 text-red-600">{error}</div>}

          <div className="mt-4 overflow-auto flex content-start flex-wrap gap-2">
            {sortedMatchingEntities.map((c) => (
              <div key={c.id}>
                <button
                  type="button"
                  className="text-left text-md px-2 py-1 border border-gray-300 rounded-md text-gray-600 hover:bg-blue-100 hover:border-blue-300"
                  onClick={() => setSelectedEntity(c)}
                >
                  {c.name}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default EntitySelect
