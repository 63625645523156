import React, { useReducer, createContext } from 'react'
import io from 'socket.io-client'
import { API_URL, fetchCfg } from '../../config'
import { setCookie, deleteCookie } from '../../common'

/*
  TODO: Toast notifications?

  Run a timer so we can tell user "Your session expires in 5 minutes. Do you want to stay logged in longer?"

*/

const AUTH_EXPIRE_MINUTES = 15
const REFRESH_TOKEN_DAYS = 45

const initialState = {
  authenticated: false,
  csrfToken: '',
  props: {},
  isAuthEmailPending: false,
  isAuthPending: false,
  actionError: null,
  msg: null,
  socket: null,
  isAdmin: false,
}

const socketOptions = {
  path: '/live',
  rememberUpgrade: true,
  transports: ['websocket', 'polling'],
  secure: true,
  rejectUnauthorized: false,
  /*
  extraHeaders: {
    cookie: 'foo=squirrelsBeCraCra',
  },
  */
}

const reducer = (state, action) => {
  return {
    ...state,
    ...action,
  }
}

const Context = createContext()

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  state.setMsg = (msg) => {
    dispatch({
      msg,
    })
  }

  const getCsrfToken = async () => {
    try {
      const res = await fetch(`${API_URL}/csrf`, {
        'Content-Type': 'application/json',
        credentials: 'include',
      })
      if (res.status !== 200) {
        return false
      }
      const json = await res.json()

      if (json.error) {
        return false
      }

      dispatch({
        csrfToken: json.csrfToken,
      })
      return json.csrfToken
    } catch (error) {
      return false
    }
  }

  state.stripePortal = async () => {
    try {
      const res = await fetch(`${API_URL}/subscribe/manage`, {
        ...fetchCfg(state.csrfToken),
        method: 'get',
      })
      const json = await res.json()
      if (res.status !== 200) {
        throw new Error(json)
      }
      return json
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  state.signup = async (user, onSuccess = () => {}) => {
    let csrfToken = state.csrfToken
    if (!csrfToken) {
      csrfToken = await getCsrfToken()
    }

    try {
      if (!csrfToken) {
        throw new Error('failed to get csrf token')
      }
      const method = state.authenticated ? 'reactivate' : 'signup'
      const res = await fetch(`${API_URL}/${method}`, {
        ...fetchCfg(csrfToken),
        method: 'post',
        body: JSON.stringify(user),
      })
      if (res.status === 429) {
        throw new Error('Too many signup attempts')
      }
      if (res.status > 499) {
        throw new Error('Something went wrong')
      }
      const json = await res.json()
      if (res.status !== 200) {
        throw new Error(json)
      }
      return json
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  state.cancelSignup = async (stripeSessionId) => {
    try {
      const res = await fetch(`${API_URL}/signup/cancel/${stripeSessionId}`, {
        ...fetchCfg(state.csrfToken),
        method: 'get',
      })
      const json = await res.json()
      if (res.status !== 200) {
        throw new Error(json)
      }
      return json
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  state.sendLoginEmail = async (email) => {
    dispatch({
      isAuthEmailPending: true,
    })

    let csrfToken = state.csrfToken
    if (!csrfToken) {
      csrfToken = await getCsrfToken()
    }

    try {
      if (!csrfToken) {
        throw Error('failed to get csrf token')
      }
      const res = await fetch(`${API_URL}/auth/send`, {
        ...fetchCfg(csrfToken),
        method: 'post',
        body: JSON.stringify({ email }),
      })
      if (res.status > 499) {
        throw new Error()
      }
      if (res.status === 429) {
        throw new Error('Too many login attempts')
      }
      const json = await res.json()
      if (res.status !== 200) {
        throw new Error(json.error)
      }

      dispatch({
        props: json,
        actionError: null,
        isAuthEmailPending: false,
      })
    } catch (error) {
      console.error(error)
      dispatch({
        isAuthEmailPending: false,
        actionError: error.message || 'something went wrong',
      })
    }
  }

  state.login = async (authCode) => {
    dispatch({
      isAuthPending: true,
    })

    let csrfToken = state.csrfToken
    if (!csrfToken) {
      csrfToken = await getCsrfToken()
    }

    try {
      if (!csrfToken) {
        throw Error('failed to get csrf token')
      }
      const res = await fetch(`${API_URL}/auth`, {
        ...fetchCfg(csrfToken),
        method: 'post',
        body: JSON.stringify({ authCode }),
      })
      if (res.status === 429) {
        throw new Error('Too many login attempts')
      }
      if (res.status > 499) {
        throw new Error()
      }

      const json = await res.json()

      if (res.status !== 200) {
        throw new Error(json.error)
      }

      dispatch({
        props: json,
        actionError: null,
        isAuthPending: false,
        authenticated: true,
      })

      state.getSocket()
      window.setTimeout(() => {
        dispatch({
          authenticated: false,
        })
        state.login()
      }, AUTH_EXPIRE_MINUTES * 60 * 1000)
      setCookie('canRefresh', true, REFRESH_TOKEN_DAYS)
    } catch (error) {
      console.error(error.message)
      dispatch({
        authenticated: false,
        isAuthPending: false,
        actionError: error.message || 'something went wrong',
      })
    }
  }

  state.logout = async () => {
    deleteCookie('canRefresh')
    deleteCookie('_csrf')
    dispatch({ ...initialState })
    await fetch(`${API_URL}/logout`, {
      ...fetchCfg(state.csrfToken),
      method: 'post',
    })
    //window.location.reload()
  }

  state.getSocket = () => {
    if (!window.BCtools?.socket) {
      window.BCtools.socket = io(API_URL, socketOptions)

      window.BCtools.socket.on('disconnect', (reason) => {
        console.log('socket disconnect', reason)
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          //socket.connect();
        }
        // else the socket will automatically try to reconnect
      })

      window.BCtools.socket.on('import', (data) => {
        if (data) {
          dispatch(data)
        }
      })
    }
    // const socketObj = state.socket || socket
    // socketObj.connect()
    return window.BCtools.socket
  }

  return (
    <Context.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Provider as UserProvider, Context as UserContext }
